import React from 'react';
import {
  Table,
  Menu,
  Icon,
  Label,
  Button,
  Responsive
} from 'semantic-ui-react';
import PropTypes from 'prop-types';

import './style.css';

class DataTable extends React.Component {
  state = {
    showingAction: false
  };

  numberOfColumns = () => {
    const { type, dataActions } = this.props;

    const result = Object.keys(type).reduce((acc, t) => {
      if (type[t].show) {
        return (acc = acc + 1);
      }
      return acc;
    }, 0);

    return dataActions.length ? result + 1 : result;
  };

  handleShowMore = e => {
    const index = e.target.getAttribute('data-item-index');
    this.setState({
      showingAction: this.state.showingAction === index ? false : index
    });
  };

  render() {
    const {
      data,
      type,
      onNextPage,
      pageNumber,
      dataActions,
      actionComponents
    } = this.props;

    return (
      <Table color="blue" striped celled className="data-table">
        <Responsive as={Table.Header} minWidth={768}>
          <Table.Row>
            {Object.keys(type).map(column => {
              if (typeof type[column] !== 'function' && type[column].show)
                return (
                  <Table.HeaderCell key={type[column].name} textAlign="center">
                    {type[column].caption}
                  </Table.HeaderCell>
                );
              return null;
            })}
            {dataActions.length > 0 && (
              <Table.HeaderCell width={2} textAlign="center">
                Acciones
              </Table.HeaderCell>
            )}
          </Table.Row>
        </Responsive>
        <Table.Body>
          {data.map((item, index) => (
            <Table.Row key={`data-${index}`}>
              {Object.keys(type).map(column =>
                typeof type[column] !== 'function' && type[column].show ? (
                  <Table.Cell
                    key={`col-${type[column].name}`}
                    textAlign="center"
                    className={type[column].type.toLowerCase()}
                  >
                    {type[column].viewParser ? (
                      <span>
                        {item[type[column].name] !== null
                          ? type[column].viewParser(
                              item[type[column].name],
                              item
                            )
                          : null}
                      </span>
                    ) : (
                      <span>{item[type[column].name]}</span>
                    )}
                  </Table.Cell>
                ) : null
              )}
              <Table.Cell textAlign="center">
                {dataActions.length > 0 && (
                  <div className="action-components-cell">
                    {dataActions.map(action => {
                      if (!action) return null;

                      return (
                        <Button
                          size="mini"
                          key={`action-${index}-${action.actionName}`}
                          icon
                          onClick={() => action.action(item)}
                          className="action-button"
                        >
                          <Icon name={action.actionIcon} />
                        </Button>
                      );
                    })}
                  </div>
                )}
                {actionComponents.length ? (
                  <div
                    className={`action-components-cell ${
                      this.state.showingAction === `item-${index}`
                        ? 'unfolded'
                        : 'folded'
                    }`}
                  >
                    <div className="action-components-container">
                      {actionComponents.map((action, i) =>
                        React.createElement(action, {
                          item,
                          key: `data-action-${index}-${i}`,
                          className: 'action-button'
                        })
                      )}
                    </div>
                    <div
                      className="show-more"
                      data-item-index={`item-${index}`}
                      onClick={this.handleShowMore}
                    />
                  </div>
                ) : null}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
        {
          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell colSpan={this.numberOfColumns()}>
                <Label size="small">Page {pageNumber}</Label>
                <Menu floated="right" pagination size="mini">
                  {
                    <Menu.Item as="a" icon onClick={onNextPage}>
                      <Icon name="chevron right" />
                    </Menu.Item>
                  }
                </Menu>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        }
      </Table>
    );
  }
}

DataTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};

export default DataTable;
