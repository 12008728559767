import React from 'react';
import { ModalContext } from './provider';

const withModal = (Component: React.ComponentType<any>) => {
  return (props: any) => {
    return (
      <ModalContext.Consumer>
        {({ state, actions }) => {
          return <Component {...props} modal={{ ...state, ...actions }} />;
        }}
      </ModalContext.Consumer>
    );
  };
};

export default withModal;
