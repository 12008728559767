import React from 'react';
import PropTypes from 'prop-types';
import { Message } from 'semantic-ui-react';

import FormComponent from '../FormComponent';

class EditComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      enableSaveButton: false,
      editing: props.editing ? props.data : this.loadDefaultData(),
      startEditing: false,
      cleanForm: false
    };
  }

  loadDefaultData = () => {
    console.log('Load default data...');
    const defaultValue = Object.keys(this.props.model.view).reduce(
      (acc, key) => {
        let defaultValue = this.props.model.view[key].defaultValue;

        if (typeof defaultValue === 'function') {
          return {
            ...acc,
            [key]: defaultValue()
          };
        }

        return {
          ...acc,
          [key]: defaultValue
        };
      },
      {}
    );
    return defaultValue;
  };

  parseBeforeSave = (data, model) => {
    Object.keys(data).forEach(fieldName => {
      const field = model.view[fieldName];
      const parser = field && field.saveParser ? field.saveParser : null;
      if (parser) {
        data[fieldName] = parser(data[fieldName]);
      }
    });

    return data;
  };

  onSubmit = data => {
    this.setState({ startEditing: false });
    this.props
      .onSubmit(this.parseBeforeSave(data, this.props.model))
      .then(() => {
        this.setState({
          editing: this.props.editing ? data : this.loadDefaultData(),
          cleanForm: this.props.editing ? false : true
        });
      });
  };

  disableSaveButton = () => {
    this.setState({ enableSaveButton: false });
  };

  enableSaveButton = () => {
    this.setState({ enableSaveButton: true });
  };

  onStartEditing = () => {
    this.setState({ startEditing: true, cleanForm: false });
  };

  render = () => {
    const { model, saved, error, loading } = this.props;

    return (
      <>
        {!this.state.startEditing && saved && !error && (
          <Message positive>
            <Message.Header>Guardado!</Message.Header>
          </Message>
        )}
        {!this.state.startEditing && error && (
          <Message negative>
            <Message.Header>
              {error.name} ({error.code})
            </Message.Header>
            <p>{error.message}</p>
          </Message>
        )}
        <FormComponent
          data={this.state.editing}
          model={model}
          onSubmit={this.onSubmit}
          loading={loading}
          onStartEditing={this.onStartEditing}
          enableSaveButton={this.state.enableSaveButton}
          cleanForm={this.state.cleanForm}
        />
      </>
    );
  };
}

EditComponent.defaultProps = {
  saved: false,
  error: false,
  data: null
};

EditComponent.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  model: PropTypes.shape({}).isRequired,
  data: PropTypes.shape({}),
  saved: PropTypes.bool,
  error: PropTypes.bool
};

export default EditComponent;
