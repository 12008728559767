import React from 'react';
import { Grid, Message, Button, Search, Dropdown } from 'semantic-ui-react';
import _ from 'lodash';

import DataTable from '../DataTable';
import { withModal } from '../../hoc/modal';

class ListComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      filteredData: [],
      searchResults: [],
      searchValue: '',
      searchBy: props.model.defaultSearchField
    };
  }

  componentWillMount() {
    this.setState({ filterData: this.props.data });
  }

  onDeleteAccept = id => {
    this.props.onDelete(id);
    this.setState({ filterFields: this.props.data });
  };

  showDeleteModal = item => {
    this.props.modal.showModal({
      title: 'Eliminar registro',
      content:
        'Seguro desea eliminar este registro? Esta acción es irreversible',
      onAccept: () => {
        this.onDeleteAccept(item.id);
        this.props.modal.closeModal();
      },
      onCancel: () => {
        this.props.modal.closeModal();
      }
    });
  };

  filterData = value => {
    const { searchBy } = this.state;
    return this.props.data.filter(item => {
      if (searchBy.type === 'Component') {
        return item[searchBy.name].name
          ? item[searchBy.name].name.startsWith(value)
          : false;
      }
      return item[searchBy.name].startsWith(value);
    });
  };

  triggerSearch = _.debounce(
    value => {
      const { searchBy } = this.state;

      this.props
        .onSearch(
          {
            field:
              searchBy.type === 'Component'
                ? searchBy.searchFieldsInCollection[0]
                : searchBy.name,
            value: value
          },
          searchBy.type === 'Component' ? searchBy.searchInCollection : ''
        )
        .then(res => {
          this.setState({
            searchResults: res ? res : [],
            filteredData: this.filterData(value)
          });
        });
    },
    500,
    { leading: true }
  );

  handleSearchChange = async (e, { value }) => {
    this.setState({ searchValue: value });
    this.triggerSearch(value);
  };

  onResultSelect = (e, data) => {
    if (this.state.searchBy.type === 'Component') return;
    this.props.onEdit(data.result);
  };

  onFieldChange = (e, option) => {
    this.setState({
      searchBy: this.props.model.filterFields.find(
        field => field.name === option.value
      ),
      searchValue: '',
      filteredData: []
    });
  };

  resultRenderer = item => {
    return <div>{item.title}</div>;
  };

  mapResults = () => {
    const { searchResults, searchBy } = this.state;
    const results = searchResults.map(item => ({
      key: item.id,
      id: item.id,
      title:
        searchBy.type === 'Component'
          ? item[searchBy.searchFieldsInCollection[0]]
          : item[searchBy.name]
    }));

    return results;
  };

  render = () => {
    const {
      data,
      model,
      onEdit,
      onDelete,
      onCreate,
      hideActions,
      loading
    } = this.props;
    const { searchValue, filteredData } = this.state;

    const [...extraActions] = model.actions ? model.actions : [];

    return (
      <Grid stackable>
        <Grid.Row columns={3} verticalAlign="middle">
          {model.filterFields.length ? (
            <>
              <Grid.Column>
                <Dropdown
                  placeholder="Buscar por"
                  selection
                  onChange={this.onFieldChange}
                  value={this.state.searchBy.name}
                  options={model.filterFields.map(field => ({
                    key: field.name,
                    text: field.caption,
                    value: field.name,
                    active: field.name === this.state.searchBy.name
                  }))}
                />
              </Grid.Column>
              <Grid.Column>
                <Search
                  loading={loading}
                  results={this.mapResults()}
                  value={searchValue}
                  onSearchChange={this.handleSearchChange}
                  onResultSelect={this.onResultSelect}
                  resultRenderer={this.resultRenderer}
                />
              </Grid.Column>
            </>
          ) : null}
          <Grid.Column textAlign="right">
            {!hideActions && (
              <Button
                primary
                size="small"
                content="Nuevo"
                icon="plus"
                labelPosition="right"
                onClick={onCreate}
              />
            )}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            {data.length > 0 && (
              <DataTable
                data={!filteredData.length ? data : filteredData}
                type={model.view}
                pageNumber={0}
                onNextPage={() => null}
                dataActions={
                  !hideActions
                    ? [
                        {
                          actionName: 'Edit',
                          actionIcon: 'edit',
                          action: onEdit
                        },
                        {
                          actionName: 'Delete',
                          actionIcon: 'trash',
                          action: onDelete
                        },
                        ...extraActions
                      ]
                    : []
                }
                actionComponents={
                  model.actionComponents ? model.actionComponents : []
                }
              />
            )}
            {!data.length && (
              <Message floating>
                <div className="text-center">No data</div>
              </Message>
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  };
}

export default withModal(ListComponent);
