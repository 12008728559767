import { StyleSheet } from '@react-pdf/renderer';
import styled from '@react-pdf/styled-components';

export const Heading = styled.Text`
  font-size: 22pt;
  color: #00ff00;
`;

export const styles = StyleSheet.create({
  body: {
    width: '100%',
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 30
  },
  page: {
    flexDirection: 'column',
    backgroundColor: '#FFFFFF',
    fontSize: '12pt'
  },
  section: {
    margin: 10,
    padding: 5,
    flexGrow: 1
  },
  h1: {
    fontSize: '18pt'
  },
  h2: {
    fontSize: '16pt'
  },
  h3: {
    fontSize: '14pt'
  },
  h4: {
    fontSize: '12pt'
  },
  underline: {
    textDecoration: 'underline'
  },
  square: {
    height: '10pt',
    width: '10pt',
    backgroundColor: '#FFFFFF',
    border: '1pt solid #000000'
  },
  squareSelected: {
    backgroundColor: '#000000',
    border: '3pt solid #FFFFFF'
  },
  textCentered: {
    textAlign: 'center'
  },
  table: {
    flexDirection: 'column',
    marginBottom: 10,
    border: '1pt solid #00000'
  },
  header: {
    flexDirection: 'column',
    backgroundColor: '#d9d9d6',
    color: 'black'
  },
  tableBody: {
    flexDirection: 'column',
    color: 'black'
  },
  row: {
    flexDirection: 'row',
    flexGrow: 1,
    borderBottom: '1pt solid #00000'
  },
  column: {
    padding: '5pt',
    flexDirection: 'column',
    flexGrow: 1,
    flexShrink: 1,
    borderRight: '1pt solid #000000'
  }
});

export default styles;
