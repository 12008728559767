import React from 'react';
import CollectionList from '../CollectionList';
import MultiselectCollectionList from '../MultiselectCollectionList';
import ABM from '../ABM';
import Create from '../ABM/components/Create';
import { Form, Button } from 'semantic-ui-react';
import { withModal } from '../../hoc/modal';
import { withCompany } from '../../hoc/company';

interface ICollectionProps {
  type: 'multi' | 'single';
  modal: any;
  model: any;
  collectionName: string;
  initialState: any;
}

class Collection extends React.Component<ICollectionProps> {
  handleAddDocument = () => {
    console.log('Add document to collection');
    this.props.modal.showModal({
      content: (
        <ABM.Component
          modal
          collectionName={this.props.collectionName}
          model={this.props.model}
        >
          <Create />
        </ABM.Component>
      ),
      onAccept: () => this.props.modal.closeModal()
    });
  };

  render() {
    const { type, ...rest } = this.props;

    return (
      <Form.Group inline>
        {type === 'multi' && <MultiselectCollectionList {...rest} />}
        {type === 'single' && <CollectionList {...rest} />}
        <Button
          primary
          type="button"
          size="tiny"
          icon="plus"
          onClick={this.handleAddDocument}
        />
      </Form.Group>
    );
  }
}

export default withCompany()(withModal(Collection));
