import React from 'react';
import * as types from './types';
import CollectionList from '../components/CollectionList';
import DocumentParser from '../components/DocumentParser';

export const initialState = [];

export const view = {
  id: {
    name: 'id',
    caption: 'id',
    type: 'Text',
    defaultValue: '',
    editable: false,
    show: false,
  },
  issueDate: {
    ...types.Timestamp,
    name: 'issueDate',
    caption: 'Date',
    type: 'Timestamp',
    editable: true,
    show: true,
  },
  name: {
    name: 'name',
    caption: 'Nombre',
    type: 'Text',
    defaultValue: '',
    editable: true,
    show: true,
  },
  item: {
    name: 'item',
    caption: 'Item',
    type: 'Component',
    defaultValue: '',
    editable: true,
    show: true,
    // Fiels indica los fields de cada documento que se deberian mostrar en el dropdown (cuando se edita) o en la tabla (cuando se lista)
    component: (props) => <CollectionList {...props} collectionName="items" fields={["name", "description"]} />,
    viewParser: (document = null) => <DocumentParser document={document} collectionName="items" fields={["name", "description"]} />
  },
  provider: {
    name: 'provider',
    caption: 'Proveedor',
    type: 'Component',
    defaultValue: '',
    editable: true,
    show: true,
    component: (props) => <CollectionList {...props} collectionName="providers" fields={["name"]} />,
    viewParser: (document = null) => <DocumentParser document={document} collectionName="providers" fields={["name"]} />
  },
  quantity: {
    name: 'quantity',
    caption: 'Cantidad',
    type: 'Number',
    defaultValue: '0',
    editable: true,
    show: true,
    validate: (value) => (types.Number.validate(value)),
  },
};

export const defaultSearchField = view.name;
export const filterFields = [view.name, view.item, view.provider];
