import React from 'react';
import * as types from './types';
import CollectionList from '../components/CollectionList';
import DocumentParser from '../components/DocumentParser';
import { shape, string } from 'prop-types';
import EppPDF from '../components/EppPDF';
import Risks from '../components/Risks';
import Accidents from '../components/Accidents';

export const propsDefinition = shape({
  name: string,
  lastName: string,
  email: string,
  phone: string
});

export const initialState = [];

export const collectionActions = ['import', 'export'];
export const documentActions = ['edit', 'delete', 'exportAsPdf'];

export const actionComponents = [EppPDF, Risks, Accidents];

export const view = {
  id: {
    name: 'id',
    caption: 'id',
    type: 'Text',
    defaultValue: '',
    editable: false,
    show: false
  },
  dni: {
    name: 'dni',
    caption: 'DNI',
    type: 'Text',
    defaultValue: '',
    editable: true,
    show: true,
    width: 4,
    validate: types.DNI.validate
  },
  name: {
    name: 'name',
    caption: 'Nombre',
    type: 'Text',
    defaultValue: '',
    editable: true,
    show: true,
    width: 4,
    validate: value => types.Name.validate(value, 'Nombre no válido')
  },
  lastName: {
    name: 'lastName',
    caption: 'Apellido',
    type: 'Text',
    defaultValue: '',
    editable: true,
    show: true,
    width: 4,
    validate: value => types.Name.validate(value, 'Apellido no válido')
  },
  email: {
    name: 'email',
    caption: 'Email',
    type: 'Email',
    defaultValue: '',
    editable: true,
    show: true,
    width: 4,
    validate: types.Email.validate
  },
  cellPhone: {
    name: 'cellPhone',
    caption: 'Celular',
    type: 'Text',
    defaultValue: '+54',
    editable: true,
    show: true,
    width: 4,
    validate: value => types.CellPhone.validate(value, 'Celular no válido')
  },
  phone: {
    name: 'phone',
    caption: 'Telefono',
    type: 'Text',
    defaultValue: '+54',
    editable: true,
    show: false,
    validate: value => types.Phone.validate(value, 'Telefono no válido')
  },
  puesto: {
    name: 'puesto',
    caption: 'Puesto',
    type: 'Component',
    defaultValue: '',
    editable: true,
    show: true,
    searchInCollection: 'jobs',
    searchFieldsInCollection: ['name'],
    component: props => (
      <CollectionList {...props} collectionName="jobs" fields={['name']} />
    ),
    viewParser: (document = null) => (
      <DocumentParser
        document={document}
        collectionName="jobs"
        fields={['name']}
      />
    ),
    saveParser: value => ({ id: value.id })
  },
  address: {
    name: 'address',
    caption: 'Domicilio',
    type: 'Text',
    defaultValue: '',
    editable: true,
    show: false
  },
  legajo: {
    name: 'legajo',
    caption: 'Legajo',
    type: 'Text',
    defaultValue: '',
    editable: true,
    show: false
  },
  emergencyPhone: {
    name: 'emergencyPhone',
    caption: 'Teléfono de emergencia',
    type: 'Text',
    defaultValue: '',
    editable: true,
    show: false
    //validate: (value) => ( types.Phone.validate(value, 'Telefono no válido'))
  },
  ART: {
    name: 'ART',
    caption: 'ART',
    type: 'Text',
    defaultValue: '',
    editable: true,
    show: false
  },
  healthInsurance: {
    name: 'healthInsurance',
    caption: 'Obra Social',
    type: 'Text',
    defaultValue: '',
    editable: true,
    show: false
  },
  CUIL: {
    name: 'CUIL',
    caption: 'CUIL',
    type: 'Text',
    defaultValue: '',
    editable: true,
    show: false
    //validate: (value) => ( types.CUIL.validate(value, 'CUIL no válido'))
  },
  risks: {
    name: 'risks',
    caption: 'Riesgos',
    type: 'Array',
    defaultValue: [],
    editable: false,
    show: false
  },
  accidents: {
    name: 'accidents',
    caption: 'Accidentes',
    type: 'Array',
    defaultValue: [],
    editable: false,
    show: false
  }
};

export const defaultSearchField = view.dni;
export const filterFields = [
  view.dni,
  view.name,
  view.lastName,
  view.email,
  view.legajo,
  view.CUIL
];
