// TODO: Replace this for environment variables
export const firebaseConfig = {
  apiKey: "AIzaSyDdNSa5VgbazF2FHUuFE6izFm6-Xv9By98",
  authDomain: "techys-dev.firebaseapp.com",
  databaseURL: "https://techys-dev.firebaseio.com",
  projectId: "techys-dev",
  storageBucket: "techys-dev.appspot.com",
  messagingSenderId: "918940130046"
}

export default { firebaseConfig };