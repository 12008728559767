import * as React from 'react';
import withFirestore from '../../hoc/firestore/withFirestore';
import { Dropdown } from 'semantic-ui-react';
import { withCompany } from '../../hoc/company';
import { InjectedCompanyProps } from '../../hoc/company/provider';

interface IMultiselectCollectionListProps {
  state?: any;
  actions?: any;
  onChange: any;
  data: any;
  collectionName: string;
  model: any;
  fields: string[];
}

interface ICollectionListState {}

class MultiSelectCollectionList extends React.Component<
  IMultiselectCollectionListProps & InjectedCompanyProps,
  ICollectionListState
> {
  componentWillMount() {
    if (!this.props.state[this.props.collectionName]) {
      this.props.actions.fetch(
        `companies/${this.props.company.state.selectedCompany.id}`,
        this.props.collectionName
      );
    }
  }

  onChange = (e, { value: values }) => {
    console.log(values);
    const documents = values.map(
      value => this.props.state[this.props.collectionName][value]
    );
    this.props.onChange(documents);
  };

  parseDocuments = (collection, fields) => {
    return collection.length
      ? collection.map((document: any) => {
          const content = fields.reduce((acc, field) => {
            if (!acc) return document[field] ? document[field] : '';
            return `${acc}${document[field] ? ', ' + document[field] : ''}`;
          }, null);

          const item = {
            key: document.id,
            value: document.id,
            text: `${content}`
          };

          return item;
        })
      : [];
  };

  render() {
    const { state, data, collectionName, model, fields } = this.props;

    const collection = state[collectionName];

    if (!collection) return null;

    const parsedDocuments: any = this.parseDocuments(
      Object.values(collection),
      fields
    );
    const parsedData: any = this.parseDocuments(data, fields).map(
      value => value.value
    );

    return (
      <Dropdown
        placeholder={model.caption}
        multiple
        search
        selection
        options={parsedDocuments}
        value={parsedData}
        onChange={this.onChange}
      />
    );
  }
}

const filterState = (state: any) => {
  return state.data;
};

export default withFirestore(filterState)(
  withCompany()(MultiSelectCollectionList)
);
