import * as EventModel from './EventModel';
import { Files } from './types';

const type = {
  name: 'type',
  caption: 'Tipo',
  type: 'Select',
  defaultValue: 'bajo',
  editable: true,
  show: true,
  width: 3,
  options: [
    {
      key: '1',
      value: 'bajo',
      text: 'Bajo'
    },
    {
      key: '2',
      value: 'medio',
      text: 'Medio'
    },
    {
      key: '3',
      value: 'alto',
      text: 'Alto'
    },
    {
      key: '4',
      value: 'muyalto',
      text: 'Muy Alto'
    }
  ]
};

const RiskModel = {
  ...EventModel,
  defaultSearchField: type,
  filterFields: [...EventModel.filterFields, type],
  view: {
    ...EventModel.view,
    files: Files('risks', true),
    type,
    responsable: {
      ...EventModel.view.responsable,
      validate: () => ({ valid: true })
    }
  }
};

export default RiskModel;
