import { Timestamp, NotEmpty } from './types';

export const initialState = [];

export const view = {
  id: {
    name: 'id',
    caption: 'id',
    type: 'Text',
    defaultValue: '',
    editable: false,
    show: false
  },
  issueDate: {
    ...Timestamp,
    name: 'issueDate',
    caption: 'Fecha creacion',
    type: 'Timestamp',
    editable: false,
    show: false
  },
  name: {
    name: 'name',
    caption: 'Nombre',
    type: 'Text',
    defaultValue: '',
    editable: true,
    show: true,
    validate: value => NotEmpty.validate(value)
  }
};

export const defaultSearchField = view.name;
export const filterFields = [view.name];
