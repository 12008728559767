import React from 'react';
import * as types from './types';
import Collection from '../components/Collection';
import DocumentParser from '../components/DocumentParser';
import * as ElementModel from './ElementModel';

export const initialState = [];

export const name = 'puesto';

export const view = {
  id: {
    name: 'id',
    caption: 'id',
    type: 'Text',
    defaultValue: '',
    editable: false,
    show: false
  },
  issueDate: {
    ...types.Timestamp,
    name: 'issueDate',
    caption: 'Fecha creacion',
    type: 'Timestamp',
    editable: false,
    show: false
  },
  name: {
    name: 'name',
    caption: 'Nombre del puesto',
    type: 'Text',
    defaultValue: '',
    editable: true,
    show: true
  },
  description: {
    name: 'description',
    caption: 'Descripción',
    type: 'Text',
    defaultValue: '',
    editable: true,
    show: true
  },
  elements: {
    name: 'elements',
    caption: 'Elementos',
    type: 'Component',
    defaultValue: [],
    editable: true,
    show: true,
    searchInCollection: 'elements',
    searchFieldsInCollection: ['name'],
    component: props => <Elements {...props} />,
    viewParser: (documents = []) =>
      documents.map(document => (
        <DocumentParser
          key={document.id}
          document={document}
          collectionName="elements"
          fields={['name']}
        />
      )),
    saveParser: values => values.map(value => ({ id: value.id }))
  }
};

const Elements = props => {
  return (
    <Collection
      {...props}
      type="multi"
      collectionName="elements"
      model={ElementModel}
      initialState={ElementModel.initialState}
      fields={['name']}
    />
  );
};

export const defaultSearchField = view.name;
export const filterFields = [view.name];
