import React from 'react';
import { Container, Segment, Header } from 'semantic-ui-react';

import ComponentContext from '../../context';

import EditComponent from '../../../EditComponent';
import { withStorage } from '../../../../hoc/storage';

class Create extends React.Component {
  static contextType = ComponentContext;

  constructor(props) {
    super(props);

    this.state = {
      saved: false,
      editing: {}
    };

    this.editFormRef = React.createRef();
  }

  loadDefaultData = () => {
    const defaultValue = Object.keys(this.props.model.view).reduce(
      (acc, key) => {
        return { ...acc, [key]: this.props.model.view[key].defaultValue };
      },
      {}
    );
    return defaultValue;
  };

  componentWillReceiveProps = newProps => {
    this.editFormRef.current.enableSaveButton();
  };

  onFormStartEditing = () => {
    // this.props.fetchingDataClear();
  };

  onSubmit = async data => {
    return this.context.actions.create(data).then(id => {
      this.setState({ saved: true });
    });
  };

  render = () => {
    const { loading } = this.context.state;
    const { model } = this.props;

    return (
      <Container>
        <Segment.Group>
          <Segment>
            <Header as="h3" textAlign="center">
              {`Nuevo ${model.name ? model.name : ''}`}
            </Header>
          </Segment>
          <Segment>
            <EditComponent
              ref={this.editFormRef}
              data={this.loadDefaultData()}
              model={model}
              loading={loading}
              onSubmit={this.onSubmit}
              onStartEditing={this.onFormStartEditing}
              saved={this.state.saved}
              editing={false}
              error={this.context.state.error}
            />
          </Segment>
        </Segment.Group>
      </Container>
    );
  };
}

export default withStorage()(Create);
