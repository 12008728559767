import { StorageContext as context, StorageProvider as provider } from './provider';
import hoc from './withStorage';

export const StorageProvider = provider;
export const StorageContext = context;
export const withStorage = hoc;

export default {
  StorageProvider,
  StorageContext,
  withStorage
};