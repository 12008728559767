import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import ReactDOM from 'react-dom';

import firebase from 'firebase/app';
import { firebaseConfig } from './config';
import Auth from './components/Auth';
import { AuthContext } from './context';

import App from './App';

// Semantic UI
import 'semantic-ui-css/semantic.min.css';
import 'react-datepicker/dist/react-datepicker.css';
import { FirestoreProvider } from './hoc/firestore';
import { CompanyProvider } from './hoc/company';

firebase.initializeApp(firebaseConfig);

ReactDOM.render(
  <Auth>
    <BrowserRouter>
      <AuthContext.Consumer>
        {({ user: { uid } }: any) => {
          if (uid) {
            return (
              <FirestoreProvider uid={uid}>
                <CompanyProvider uid={uid}>
                  <App />
                </CompanyProvider>
              </FirestoreProvider>
            );
          }

          return <App />;
        }}
      </AuthContext.Consumer>
    </BrowserRouter>
  </Auth>,
  document.getElementById('root')
);
