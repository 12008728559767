import React from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import {
  Menu,
  Container,
  Dropdown,
  Image,
  Button,
  Icon,
  Segment,
  Responsive,
  Header,
} from 'semantic-ui-react';
import { AuthContext } from '../../context';

import Logout from '../Logout';

import './style.css';

const UserDetails = ({ user }) => (
  <Container fluid>
    <Header>
      <Image src={user.photoURL} size='small' centered circular/><br />
      <p style={{'textAlign': 'center'}}>{`${user.displayName}`}</p>
    </Header>
    <Segment basic>
      <Button icon compact labelPosition='left' onClick={() => {} }>
        <Icon name='user circle' />
        Profile
      </Button>
      <Logout>
        <Button icon compact labelPosition='right'>
          <Icon name='power off' />
          Logout
        </Button>
      </Logout>
    </Segment>
  </Container>
);

UserDetails.propTypes = {
  user: PropTypes.shape({}).isRequired
};

const HeaderComponent = ({ match, routes }) => {
  const userMenu = (
    <Menu.Menu key="user-menu" position="right" active="false">
      <AuthContext.Consumer>
        {
          ({ user }) => (
            <Dropdown
              key="mobile-menu"
              text={user.email}
              item
              simple
              direction="right"
              options={[<UserDetails key="user-details" user={user} />]} />
          )
        }
      </AuthContext.Consumer>
    </Menu.Menu>
  );

  const items = routes.map(route => (
    <Menu.Item key={`menu-${route.title}`} as={Link} to={`${match.path}/${route.path}`}>
      <Icon name={route.icon} />
      { route.title }
    </Menu.Item>
  ));

  if(items.length > 5) {

  }

  const desktopItems = items.length > 5 ? items.slice(0, 6) : items;
  const desktopRest = items.length > 5 ? items.slice(6, routes.length) : [];

  const mobileMenu = (items, text) => {
    if (!items.length) return null;
    return (
      <Dropdown
        key="mobile-menu"
        active="false"
        text={text}
        item
        simple
        direction="right"
        options={items}
      />
    );
  };

  return (
    <Container fluid className="header-component">
      <Responsive minWidth={768}>
        <Menu
          borderless
          fluid
          style={{ "flexWrap": "wrap" }}
          items={[...desktopItems, mobileMenu(desktopRest, 'Mas'), userMenu]}
        />
      </Responsive>
      <Responsive maxWidth={767}>
        <Menu
          borderless
          fluid
          items={[mobileMenu(items, 'Menu'), userMenu]}
        />
      </Responsive>
    </Container>
  );
};

HeaderComponent.propTypes = {};

export default withRouter(HeaderComponent);
