import React from 'react';
import PropTypes from 'prop-types';

import ComponentContext from '../../context';

import ListComponent from '../../../ListComponent';
import {
  Segment,
  Container,
  Modal,
  Button,
  Dimmer,
  Loader
} from 'semantic-ui-react';
import CollectionActions from '../../../CollectionActions';

class List extends React.Component {
  static contextType = ComponentContext;

  state = {
    openModal: false,
    toDelete: null
  };

  componentDidMount = () => {
    // Si hay un solo registro supongo q viene de editar previo refresh entonces hago fetch de todo
    this.context.actions.fetch();
  };

  closeModal = () => {
    this.setState({ openModal: false, toDelete: null });
  };

  onEdit = item => {
    this.props.history.push({
      pathname: `${this.props.location.pathname}/edit/${item.id}`
    });
  };

  onCreate = () => {
    this.props.history.push(`${this.props.location.pathname}/create`);
  };

  onDelete = () => {
    const item = this.state.toDelete;
    if (!item) return;
    this.context.actions.delete(item.id).then(() => {
      this.setState({ openModal: false, toDelete: null });
    });
  };

  handleDelete = item => {
    this.setState({
      openModal: true,
      toDelete: item
    });
  };

  onSearch = async (value, inCollection) => {
    return this.context.actions.search(value, inCollection);
  };

  arrayToObject = data => {
    return data.reduce((acc, document) => {
      return { ...acc, [document.id]: document };
    }, {});
  };

  onLoadCSV = data => {
    this.context.actions.loadCSV(this.arrayToObject(data));
  };

  render() {
    const { loading, data } = this.context.state;
    const { model } = this.props;

    const dataArray = Object.values(data);

    return (
      <Container fluid>
        <Segment.Group>
          {model.collectionActions && (
            <Segment>
              <CollectionActions
                model={model}
                data={dataArray}
                onLoadCSV={this.onLoadCSV}
              />
            </Segment>
          )}
          <Segment loading={!dataArray.length && loading}>
            <ListComponent
              model={this.props.model}
              data={dataArray}
              onEdit={this.onEdit}
              onDelete={this.handleDelete}
              onCreate={this.onCreate}
              onSearch={this.onSearch}
              loading={loading}
            />
          </Segment>
        </Segment.Group>
        <Modal
          size="mini"
          open={this.state.openModal}
          onClose={this.closeModal}
        >
          <Modal.Header>Eliminar registro</Modal.Header>
          <Modal.Content>
            {loading && (
              <Dimmer active>
                <Loader />
              </Dimmer>
            )}
            <div>¿Seguro desea eliminar este registro?</div>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={this.onDelete} color="green">
              Si
            </Button>
            <Button onClick={this.closeModal} color="red">
              No
            </Button>
          </Modal.Actions>
        </Modal>
      </Container>
    );
  }
}

List.defaultProps = {
  loading: false
};

List.propTypes = {
  loading: PropTypes.oneOfType([PropTypes.bool, PropTypes.string])
};

export default List;
