import React from "react";
import { Link } from 'react-router-dom';
import {
  Segment,
  List,
  Icon
} from 'semantic-ui-react';

const SideMenuComponent = ({baseRoute, routes}) => (
  <Segment>
    <List divided relaxed animated link size="small">
      {
        routes.map((route, index) => (
          <List.Item as={Link} key={index} to={`${baseRoute}/${route.path}`}>
            <Icon name={route.icon} link/>{route.title}
          </List.Item>
        ))
      }
    </List>
  </Segment>
)

export default SideMenuComponent;