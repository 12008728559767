import React from 'react';
import { CSVLink } from 'react-csv';
import { Button } from 'semantic-ui-react';

class ExportToCSV extends React.Component {
  render() {
    const { data, model } = this.props;
    const headers = Object.values(model).map(field => { return {label: field.name, key: field.name};});
    return(
      <CSVLink data={data} headers={headers}>
        <Button
          color="green"
          size='small'
          content='Exportar CSV'
          icon='download'
          labelPosition='right' />
      </CSVLink>
    );
  }
}

export default ExportToCSV;
