import React from 'react';
import PropTypes from 'prop-types';

import ImportCSV from '../ImportCSV';
import ExportToCSV from '../ExportToCSV';

class CollectionActions extends React.Component {

  render() {
    const { model, data, onLoadCSV } = this.props;

    if (!model.collectionActions) return null;

    return (
      <>
        {
          (model.collectionActions.includes('import')) &&
          <ImportCSV model={model.view} onLoad={onLoadCSV} />

        }
        {
          (model.collectionActions.includes('export')) &&
          <ExportToCSV model={model.view} data={data} />
        }
      </>
    );
  }
}

CollectionActions.propTypes = {
  model: PropTypes.shape({}).isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({})),
  onLoadCSV: PropTypes.func
};

export default CollectionActions;