import React from 'react';
import {
  Modal,
  Button,
  ModalProps,
  Message,
  ButtonProps
} from 'semantic-ui-react';
import { withFirestore } from '../../hoc/firestore';
import Uploader from './uploader';

type ModalAction = {
  caption?: any;
  icon?: ButtonProps['icon'];
  onClick: () => void;
  color: ButtonProps['color'];
};

type ModalConfig = {
  title: string;
  content: any;
  size?: ModalProps['size'];
  actions: ModalAction[];
};

type State = {
  loading: boolean;
  saved?: boolean;
  openModal: boolean;
  modal: ModalConfig;
};

class FilesUploader extends React.Component<any, State> {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      openModal: false,
      saved: false,
      modal: {
        loading: false,
        openModal: false,
        title: '',
        content: null,
        actions: []
      } as ModalConfig
    };
  }

  onCancel = () => {
    this.setState({ openModal: false, saved: false });
  };

  onUploadComplete = file => {
    const { state, document, actions, collection, fieldKey } = this.props;

    const files = document.files ? document.files : [];

    if (!fieldKey) {
      actions.update(document.parentPath, collection, document.id, {
        ...document,
        files: [...files, ...file]
      });
    } else {
      const documentToUpdate =
        state.data[document.parentCollection][document.parentDocumentId];

      const newValues = documentToUpdate[collection].map(item => {
        if (item.id === document.id)
          return {
            ...item,
            files: [...files, ...file]
          };
        return item;
      });

      actions.update(
        document.parentPath,
        document.parentCollection,
        document.parentDocumentId,
        {
          ...documentToUpdate,
          [collection]: newValues
        }
      );
    }
  };

  onDeleteComplete = deletedFile => {
    const { state, document, actions, collection, fieldKey } = this.props;
    const files = document.files ? document.files : [];

    const reduced = files.filter(file => file.name !== deletedFile.name);

    if (!fieldKey) {
      actions
        .update(document.parentPath, collection, document.id, {
          ...document,
          files: reduced
        })
        .then(() => {
          this.setState({ loading: false });
        });
    } else {
      const documentToUpdate =
        state.data[document.parentCollection][document.parentDocumentId];

      const newValues = documentToUpdate[collection].map(item => {
        if (item.id === document.id) {
          return {
            ...item,
            files: item.files.filter(file => file.name !== deletedFile.name)
          };
        }

        return item;
      });

      actions.update(
        document.parentPath,
        document.parentCollection,
        document.parentDocumentId,
        {
          ...documentToUpdate,
          [collection]: newValues
        }
      );
    }
  };

  onButtonClick = () => {
    this.setState({
      modal: {
        title: 'Archivos',
        content: (
          <Uploader
            files={this.props.files}
            uploadPath={this.props.uploadPath}
            onUploadComplete={this.onUploadComplete}
            onDeleteComplete={this.onDeleteComplete}
          />
        ),
        actions: [
          {
            icon: 'checkmark',
            onClick: this.onCancel,
            color: 'green'
          }
        ]
      },
      openModal: true
    });
  };

  render() {
    const {
      state: { loading, error }
    } = this.props;
    const { modal, saved } = this.state;

    return (
      <>
        <Button
          primary
          type="button"
          size="tiny"
          icon="copy"
          onClick={this.onButtonClick}
        />
        <Modal
          open={this.state.openModal}
          onClose={this.onCancel}
          size={modal.size || 'tiny'}
        >
          <Modal.Header>{modal.title}</Modal.Header>
          <Modal.Content scrolling>
            {modal.content}
            {saved && !error && (
              <Message size="tiny" positive>
                Guardado!
              </Message>
            )}
          </Modal.Content>
          <Modal.Actions>
            {modal.actions.map((action, index) => (
              <Button
                icon={action.icon && action.icon}
                key={action.caption ? action.caption : index}
                color={action.color}
                onClick={action.onClick}
              >
                {action.caption && action.caption}
              </Button>
            ))}
          </Modal.Actions>
        </Modal>
      </>
    );
  }
}

export default withFirestore()(FilesUploader);
