import React from 'react';
import BaseComponent from '../BaseComponent';
import routes from './routes';
import ComponentContext from './context';
import constants from './constants';
import { withFirestore } from '../../hoc/firestore';
import { withCompany } from '../../hoc/company';

class ABMComponent extends React.Component {
  render() {
    const {
      collectionName,
      model,
      state,
      actions,
      company: {
        state: { selectedCompany }
      },
      modal,
      children
    } = this.props;

    const collectionData = state.data[collectionName];

    const parentCollection =
      selectedCompany && collectionName !== 'companies'
        ? `companies/${selectedCompany.id}`
        : '';
    return (
      <ComponentContext.Provider
        value={{
          state: {
            ...state,
            data: collectionData ? collectionData : {}
          },
          actions: {
            fetch: () => actions.fetch(parentCollection, collectionName),
            get: documentId =>
              actions.get(parentCollection, collectionName, documentId),
            delete: documentId =>
              actions.delete(parentCollection, collectionName, documentId),
            update: (documentId, data) =>
              actions.update(
                parentCollection,
                collectionName,
                documentId,
                data
              ),
            create: data =>
              actions.create(parentCollection, collectionName, data),
            search: (data, inCollection) =>
              actions.search(
                parentCollection,
                inCollection ? inCollection : collectionName,
                data
              ),
            loadCSV: data =>
              actions.loadBatch(parentCollection, collectionName, data)
          }
        }}
      >
        {!modal && <BaseComponent routes={routes} model={model} />}
        {children ? React.cloneElement(children, { model }) : null}
      </ComponentContext.Provider>
    );
  }
}

export default {
  Component: withFirestore()(withCompany()(ABMComponent)),
  routes,
  constants
};
