import React from 'react';
import { Button, Loader, Divider, Dimmer, Container } from 'semantic-ui-react';
import { withStorage } from '../../hoc/storage';

import './uploader.css';

const File = ({ file, onDelete }) => {
  return (
    <div className="file">
      <a
        className="content"
        href={file.downloadURL}
        target="_blank"
        rel="noopener noreferrer"
      >
        {file.type.includes('image') ? (
          <img
            key={file.name}
            width="50px"
            src={file.preview}
            alt={file.name}
          />
        ) : (
          file.name
        )}
      </a>
      <div className="delete" onClick={onDelete}>
        ✖︎
      </div>
    </div>
  );
};

const List = ({ files, onDelete }) => {
  if (!files.length)
    return (
      <div>
        <p>Todavia no hay archivos.</p>
        <p>Para agregar archivos haga click en "+"</p>
        <p>Una vez agregados todos los archivos haga click en "Subir"</p>
      </div>
    );
  return (
    <div className="file-list">
      {files.map(file => (
        <File
          key={`${Date.now()}-${file.name}`}
          file={file}
          onDelete={() => onDelete(file)}
        />
      ))}
    </div>
  );
};

class Uploader extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      preview: null,
      toAdd: [],
      toDelete: [],
      files: this.props.files,
      loading: false
    };

    this.inputRef = React.createRef();
  }

  onAdd = () => {
    this.inputRef.current.click();
  };

  onSave = () => {
    this.setState({ loading: true });
    this.uploadFiles(this.state.toAdd).then(() => {
      this.setState({
        loading: false,
        toAdd: [],
        files: [...this.state.files, ...this.state.toAdd]
      });
    });
  };

  deleteFile = toDelete => {
    this.setState({ loading: true });
    this.props.storage
      .deleteFile({
        path: `${this.props.uploadPath}`,
        file: toDelete
      })
      .then(res => {
        // Update the document with the new files
        this.props.onDeleteComplete(toDelete);
        this.setState({
          loading: false,
          toAdd: this.state.toAdd.filter(file => file.name !== toDelete.name),
          files: this.state.files.filter(file => file.name !== toDelete.name)
        });
      });
  };

  onChange = () => {
    const file = this.inputRef.current.files[0];
    if (!file) return;
    this.getBase64(file).then(data => {
      this.setState({
        toAdd: [
          ...this.state.toAdd,
          { name: file.name, type: file.type, preview: data, file }
        ]
      });
    });
  };

  getBase64 = file => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  };

  uploadFiles = async files => {
    if (!files.length) return;

    // Upload all files to the store
    return Promise.all(
      files.map(file => {
        return this.props.storage.uploadFile({
          path: `${this.props.uploadPath}`,
          file
        });
      })
    ).then(res => {
      this.props.onUploadComplete(res);
    });
  };

  render() {
    const { loading, toAdd, files } = this.state;

    if (loading)
      return (
        <div>
          <Dimmer active inverted>
            <Loader inverted content="Por favor espere..." />
          </Dimmer>
        </div>
      );
    return (
      <Container className="uploader-component">
        <div className="list-container">
          <List files={[...files, ...toAdd]} onDelete={this.deleteFile} />
        </div>
        <Divider />
        <Button
          primary
          type="button"
          size="tiny"
          icon="plus"
          onClick={this.onAdd}
        />
        <Button
          primary
          disabled={!toAdd.length}
          type="button"
          size="tiny"
          content="Subir"
          onClick={this.onSave}
        />
        {toAdd.length ? (
          <span>Algunos archivos no fueron subidos todavía.</span>
        ) : null}
        <input
          ref={this.inputRef}
          type="file"
          accept="image/*,.pdf"
          onChange={this.onChange}
          style={{ display: 'none' }}
        />
      </Container>
    );
  }
}

export default withStorage()(Uploader);
