import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  Container,
  Divider,
  Grid,
  Header,
  Icon,
  Image,
  List,
  Menu,
  Responsive,
  Segment,
  Sidebar,
  Visibility,
  Comment,
  Label,
  Item
} from 'semantic-ui-react';

import * as Scroll from 'react-scroll';

import ContactComponent from '../../components/Contact';

import './style.css';
// import headerBgImage from './img/background-1.jpg';

/* eslint-disable react/no-multi-comp */
/* Heads up! HomepageHeading uses inline styling, however it's not the best practice. Use CSS or styled components for
 * such things.
 */
const HomepageHeading = ({ mobile }) => (
  <Container text className="header-component">
    <Image src="img/logo.png" size="medium" centered />
    <Header
      as="h1"
      content="Software de Gestión Integral de HyS"
      inverted
      style={{
        fontSize: mobile ? '3em' : '3em',
        fontWeight: 'normal'
      }}
    />
    <Scroll.Link
      className="ui button primary huge"
      activeClass="active"
      to="contact"
      smooth="true"
    >
      Solicitar Asistencia
      <Icon name="right arrow" />
    </Scroll.Link>
  </Container>
);

HomepageHeading.propTypes = {
  mobile: PropTypes.bool
};

/* Heads up!
 * Neither Semantic UI nor Semantic UI React offer a responsive navbar, however, it can be implemented easily.
 * It can be more complicated, but you can create really flexible markup.
 */
class DesktopContainer extends Component {
  state = {};

  hideFixedMenu = () => this.setState({ fixed: false });
  showFixedMenu = () => this.setState({ fixed: true });

  render() {
    const { children } = this.props;
    const { fixed } = this.state;

    return (
      <Responsive
        minWidth={Responsive.onlyTablet.minWidth}
        className="home-page"
      >
        <Visibility
          once={false}
          onBottomPassed={this.showFixedMenu}
          onBottomPassedReverse={this.hideFixedMenu}
        >
          <Segment
            id="home"
            inverted
            textAlign="center"
            style={{
              minHeight: '100vh',
              padding: '1em 0em',
              background: `linear-gradient(rgba(0, 0, 0, 0.45),rgba(0, 0, 0, 0.45)), url('img/background-1.jpg')`,
              backgroundSize: 'cover'
            }}
            vertical
          >
            <Grid style={{ minHeight: '100vh' }}>
              <Grid.Row style={{ height: '10%' }}>
                <Grid.Column width={16}>
                  <TechysMenu fixed={fixed} />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row style={{ height: '90%' }}>
                <Grid.Column width={16} verticalAlign="middle">
                  <HomepageHeading />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        </Visibility>
        {children}
      </Responsive>
    );
  }
}

DesktopContainer.propTypes = {
  children: PropTypes.node
};

class MobileContainer extends Component {
  state = {};

  handlePusherClick = () => {
    const { sidebarOpened } = this.state;

    if (sidebarOpened) this.setState({ sidebarOpened: false });
  };

  handleToggle = () =>
    this.setState({ sidebarOpened: !this.state.sidebarOpened });

  render() {
    const { children } = this.props;
    const { sidebarOpened } = this.state;

    return (
      <Responsive maxWidth={Responsive.onlyMobile.maxWidth}>
        <Sidebar.Pushable>
          <TechysMenu mobile sidebarOpened={sidebarOpened} />
          <Sidebar.Pusher
            dimmed={sidebarOpened}
            onClick={this.handlePusherClick}
            style={{ minHeight: '100vh' }}
          >
            <Segment
              id="home"
              inverted
              textAlign="center"
              style={{
                minHeight: '100vh',
                padding: '1em 0em',
                backgroundImage: `url('img/background-1.jpg')`
              }}
              vertical
            >
              <Container>
                <Menu inverted secondary size="large">
                  <Menu.Item onClick={this.handleToggle}>
                    <Icon name="sidebar" />
                  </Menu.Item>
                  <Menu.Item position="right">
                    <Link to="/login">
                      <Button inverted>Login</Button>
                    </Link>
                  </Menu.Item>
                </Menu>
              </Container>
              <HomepageHeading mobile />
            </Segment>

            {children}
          </Sidebar.Pusher>
        </Sidebar.Pushable>
      </Responsive>
    );
  }
}

MobileContainer.propTypes = {
  children: PropTypes.node
};

const TechysMenu = ({ fixed, mobile, sidebarOpened }) => {
  const items = () => (
    <React.Fragment>
      <Scroll.Link
        className="item"
        activeClass="active"
        to="home"
        smooth="true"
      >
        Inicio
      </Scroll.Link>
      <Scroll.Link
        className="item"
        activeClass="active"
        to="description"
        smooth="true"
        offset={10}
      >
        Servicio
      </Scroll.Link>
      <Scroll.Link className="item" activeClass="active" to="us" smooth="true">
        Nosotros
      </Scroll.Link>
      <Scroll.Link
        className="item"
        activeClass="active"
        to="clients"
        smooth="true"
      >
        Clientes
      </Scroll.Link>
      <Scroll.Link
        className="item"
        activeClass="active"
        to="contact"
        smooth="true"
      >
        Contacto
      </Scroll.Link>
    </React.Fragment>
  );

  if (mobile) {
    return (
      <Sidebar
        as={Menu}
        animation="uncover"
        inverted
        vertical
        visible={sidebarOpened}
      >
        {items()}
      </Sidebar>
    );
  }

  return (
    <Menu
      fixed={fixed ? 'top' : null}
      inverted={!fixed}
      secondary={!fixed}
      size="large"
    >
      <Container>
        {items()}
        <Menu.Item position="right">
          <Link to="/login">
            <Button inverted={!fixed} primary={fixed}>
              Login
            </Button>
          </Link>
        </Menu.Item>
      </Container>
    </Menu>
  );
};

TechysMenu.propTypes = {
  fixed: PropTypes.bool,
  menu: PropTypes.bool
};

const ResponsiveContainer = ({ children }) => (
  <div>
    <DesktopContainer>{children}</DesktopContainer>
    <MobileContainer>{children}</MobileContainer>
  </div>
);

ResponsiveContainer.propTypes = {
  children: PropTypes.node
};

const ContactForm = () => (
  <Segment color="blue">
    <ContactComponent />
  </Segment>
);

const HomepageLayout = () => (
  <ResponsiveContainer>
    <Segment id="description" style={{ padding: '4em 0em' }} vertical>
      <Grid
        container
        stackable
        verticalAlign="middle"
        style={{ minHeight: '100vh' }}
      >
        <Grid.Row>
          <Grid.Column width={8}>
            <Header as="h3" style={{ fontSize: '2.5em' }}>
              ¿Que hacemos?
            </Header>
            <p style={{ fontSize: '1.33em' }}>
              Techys es un sistema de gestión integral de la hys, con el cual
              usted podrá gestionar de manera rápida y sencilla todas las
              actividades en las cuales tiene incumbencia su equipo de hys,
              desde accidentes, avisos de riesgos, capacitaciones, entrega de
              EPP, mediaciones, y muchas cosas más!. Techys es un sistema para
              empresas de cualquier envergadura desde pymes hasta empresas
              grandes
            </p>
            <Header as="h3" style={{ fontSize: '2em' }}>
              Con TechHyS podrás
            </Header>
            <List>
              <List.Item>
                <List.Icon name="checkmark" />
                <List.Content>Analizar Riesgos y Accidentes</List.Content>
              </List.Item>
              <List.Item>
                <List.Icon name="checkmark" />
                <List.Content>Registrar entrega de EPP</List.Content>
              </List.Item>
              <List.Item>
                <List.Icon name="checkmark" />
                <List.Content>Tener control de tu Stock</List.Content>
              </List.Item>
              <List.Item>
                <List.Icon name="checkmark" />
                <List.Content>Generar múltiples reportes</List.Content>
              </List.Item>
              <List.Item>
                <List.Icon name="checkmark" />
                <List.Content>
                  Firmar electrónicamente tus documentos
                </List.Content>
              </List.Item>
            </List>
          </Grid.Column>
          <Grid.Column floated="right" width={8}>
            <Image fluid src="img/preview.png" />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>

    <Segment id="us" style={{ padding: '4em 0em' }} vertical>
      <Grid
        container
        stackable
        verticalAlign="top"
        style={{ minHeight: '100vh' }}
      >
        <Grid.Row>
          <Grid.Column width={16}>
            <Container text>
              <Divider
                as="h2"
                className="header"
                horizontal
                style={{ margin: '3em 0em', textTransform: 'uppercase' }}
              >
                <b>Nosotros</b>
              </Divider>

              <Header as="h3" style={{ fontSize: '2em' }}>
                ¿Quienes Somos?
              </Header>
              <p style={{ fontSize: '1.33em' }}>
                Somos un equipo de trabajo formado por un Ing. Industrial y un
                Tec. en Higiene y Seguridad laboral, que viendo las diferentes
                realidades de las empresas que nos rodean, decidimos buscar una
                solución a la problemática existente en los departamentos de
                higiene y seguridad de las diferentes empresas de la región.
                Ante todo esto decidimos crear un sistema de gestión,
                administración y control de las diferentes áreas de donde dicho
                departamento tiene incumbencia.
              </p>
            </Container>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row textAlign="center">
          <Grid.Column width={8}>
            <Item>
              <Item.Image size="tiny" src="img/no-user.png" />
              <Item.Content>
                <Item.Header as="h4">
                  Matias Cossio
                  <Header.Subheader>Co-Funder</Header.Subheader>
                </Item.Header>
                <Item.Description>Ingeniero Industrial</Item.Description>
              </Item.Content>
            </Item>
          </Grid.Column>
          <Grid.Column width={8}>
            <Item>
              <Item.Image size="tiny" src="img/no-user.png" />
              <Item.Content>
                <Item.Header as="h4">
                  Ignacio Ponce Molina
                  <Header.Subheader>Co-Funder</Header.Subheader>
                </Item.Header>
                <Item.Description>
                  Técnico en Higiene y Seguridad
                </Item.Description>
              </Item.Content>
            </Item>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>

    <Segment id="clients" style={{ padding: '4em 0em' }} vertical>
      <Grid
        celled="internally"
        columns="equal"
        stackable
        style={{ minHeight: '100vh' }}
      >
        <Grid.Row textAlign="center">
          <Container text>
            <Divider
              as="h2"
              className="header"
              horizontal
              style={{ margin: '3em 0em', textTransform: 'uppercase' }}
            >
              <b>Clientes</b>
            </Divider>

            <Grid columns="equal">
              <Grid.Row>
                <Grid.Column>
                  <Segment padded>
                    <Label attached="bottom">Company Name</Label>
                    <Image src="img/logo.png" size="tiny" rounded centered />
                  </Segment>
                </Grid.Column>
                <Grid.Column>
                  <Segment padded>
                    <Label attached="bottom">Company Name</Label>
                    <Image src="img/logo.png" size="tiny" rounded centered />
                  </Segment>
                </Grid.Column>
                <Grid.Column>
                  <Segment padded>
                    <Label attached="bottom">Company Name</Label>
                    <Image src="img/logo.png" size="tiny" rounded centered />
                  </Segment>
                </Grid.Column>
                <Grid.Column>
                  <Segment padded>
                    <Label attached="bottom">Company Name</Label>
                    <Image src="img/logo.png" size="tiny" rounded centered />
                  </Segment>
                </Grid.Column>
                <Grid.Column>
                  <Segment padded>
                    <Label attached="bottom">Company Name</Label>
                    <Image src="img/logo.png" size="tiny" rounded centered />
                  </Segment>
                </Grid.Column>
              </Grid.Row>
            </Grid>

            <Divider />

            <Comment.Group>
              <Comment>
                <Comment.Avatar src="img/no-user.png" />
                <Comment.Content>
                  <Comment.Author as="a">Juan Perez</Comment.Author>
                  <Comment.Text>
                    Muy útil! TechHyS me ayudó a ordenar mi trabajo diario como
                    profesional de HyS
                  </Comment.Text>
                </Comment.Content>
              </Comment>
              <Comment>
                <Comment.Avatar src="img/no-user.png" />
                <Comment.Content>
                  <Comment.Author as="a">Pepe Hongo</Comment.Author>
                  <Comment.Text>
                    Ahorro significativo de tiempo y trabajo
                  </Comment.Text>
                </Comment.Content>
              </Comment>
            </Comment.Group>
          </Container>
        </Grid.Row>
      </Grid>
    </Segment>

    <Segment id="contact" style={{ padding: '4em 0em' }} vertical>
      <Grid
        container
        stackable
        verticalAlign="top"
        style={{ minHeight: '100vh' }}
      >
        <Grid.Row>
          <Grid.Column width={16}>
            <Container text>
              <Divider
                as="h2"
                className="header"
                horizontal
                style={{ margin: '3em 0em', textTransform: 'uppercase' }}
              >
                <b>Contacto</b>
              </Divider>

              <ContactForm />
            </Container>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>

    <Segment inverted vertical style={{ padding: '2em 0em' }}>
      <Container>
        <Grid inverted columns="equal">
          <Grid.Row centered verticalAlign="top">
            <Grid.Column>
              <Header inverted as="h4" content="Contacto" />
              <List>
                <List.Item>
                  <List.Icon name="mail" />
                  <List.Content>
                    <a href="mailto:soporte@techysweb.com">
                      soporte@techysweb.com
                    </a>
                  </List.Content>
                </List.Item>
                <List.Item>
                  <List.Icon name="phone" />
                  <List.Content>381-5707740 / 381-5804541</List.Content>
                </List.Item>
                <List.Item>
                  <List.Icon name="marker" />
                  <List.Content>
                    San Miguel de Tucumán, Tucumán, Argentina
                  </List.Content>
                </List.Item>
              </List>
            </Grid.Column>
            <Grid.Column>
              <Header as="h4" inverted content="Social" />
              <List>
                <List.Item>
                  <List.Icon name="facebook" />
                  <List.Content>
                    <a href="https://facebook.com./techysweb">
                      Facebook TecHyS
                    </a>
                  </List.Content>
                </List.Item>
                <List.Item>
                  <List.Icon name="twitter" />
                  <List.Content>
                    <a href="@techysweb">@techysweb</a>
                  </List.Content>
                </List.Item>
              </List>
            </Grid.Column>
            <Grid.Column verticalAlign="middle" textAlign="center">
              © 2018-2019 TecHyS
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </Segment>
  </ResponsiveContainer>
);

export default HomepageLayout;
