import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Header, Container, Grid } from 'semantic-ui-react';
import { withRouter } from 'react-router';

import './style.css';

/* const getLinks = pathname => {
  const paths = pathname.split('/').filter(path => path !== '');
  const links = paths.reduce((acc, path) => {
    if (!acc.length) return [...acc, { path: `/${path}`, name: path }];
    return [
      ...acc,
      { path: `${acc[acc.length - 1].path}/${path}`, name: path }
    ];
  }, []);

  return links;
}; */

const ContentComponent = props => {
  // const links = getLinks(props.location.pathname);

  return (
    <Container className="content-component">
      <Grid>
        <Grid.Row>
          <Grid.Column width={16}>
            <Header as="h2" block color="yellow">
              <Icon name={props.icon} />
              <Header.Content>
                {props.title}
                <Header.Subheader>{props.subtitle}</Header.Subheader>
              </Header.Content>
            </Header>
          </Grid.Column>
          {/* <Grid.Column width={16} textAlign="left" verticalAlign="bottom">
            <Breadcrumb>
              {links.map(link => {
                if (link) {
                  return (
                    <span key={`bread-m${link.name}`}>
                      <Breadcrumb.Section>
                        <Link to={link.path}>{link.name}</Link>
                      </Breadcrumb.Section>
                      <Breadcrumb.Divider icon="right angle" />
                    </span>
                  );
                }
                return null;
              })}
            </Breadcrumb>
          </Grid.Column> */}
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={16}>{props.children}</Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  );
};

ContentComponent.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  children: PropTypes.shape(React.Component).isRequired
};

export default withRouter(ContentComponent);
