import React from 'react';
import { withModal, InjectedModalProps } from '../../hoc/modal';
import { Button, Icon, IconProps, ButtonProps } from 'semantic-ui-react';
import { withCompany } from '../../hoc/company';
import { InjectedCompanyProps } from '../../hoc/company/provider';
import { withFirestore, InjectedFirestoreProps } from '../../hoc/firestore';
import ModalList from '../ModalList/ModalList';

type ModalListComponentProps = {
  model: any;
  document: any;
  collectionName: string;
  field: string;
  buttonText: string;
  buttonIcon: IconProps['name'];
  buttonColor: ButtonProps['color'];
} & InjectedModalProps &
  InjectedFirestoreProps &
  InjectedCompanyProps;

class ModalListComponent extends React.Component<ModalListComponentProps, any> {
  state = {
    loading: false
  };

  onCreate = async data => {
    console.log('Create', data);
    const { document, field, company, actions, collectionName } = this.props;

    const values = document[field] || [];

    const response = await actions.update(
      `companies/${company.state.selectedCompany.id}`,
      `${collectionName}`,
      document.id,
      {
        ...document,
        [field]: [
          ...values,
          {
            ...data,
            id: `${Date.now()}`,
            responsable: { id: document.id },
            parentPath: `${document.parentPath}`,
            parentCollection: collectionName,
            parentDocumentId: `${document.id}`
          }
        ]
      }
    );

    return response;
  };

  onEdit = async data => {
    console.log('Edit', data);
    const { document, field, company, actions, collectionName } = this.props;

    const values = document[field] || [];

    const updated = values.map(value => {
      if (value.id === data.id) return data;
      return value;
    });

    const response = await actions.update(
      `companies/${company.state.selectedCompany.id}`,
      `${collectionName}`,
      document.id,
      { ...document, [field]: updated }
    );

    return response;
  };

  onDelete = async item => {
    console.log('Delete ', item);
    const { document, field, company, actions, collectionName } = this.props;

    const newArray = document[field].filter(v => v.id !== item.id);

    const response = await actions.update(
      `companies/${company.state.selectedCompany.id}`,
      `${collectionName}`,
      document.id,
      { ...document, [field]: newArray }
    );

    return response;
  };

  handleOpenModal = () => {
    const {
      document: { id },
      field,
      collectionName,
      buttonText
    } = this.props;

    this.props.modal.showModal({
      size: 'large',
      title: buttonText,
      content: (
        <ModalList
          dataSelector={state =>
            state[collectionName] ? state[collectionName][id][field] : []
          }
          model={this.props.model}
          onCreate={this.onCreate}
          onDelete={this.onDelete}
          onEdit={this.onEdit}
        />
      ),
      onAccept: this.props.modal.closeModal
    });
  };

  render() {
    return (
      <Button
        size="mini"
        color={this.props.buttonColor}
        icon
        onClick={this.handleOpenModal}
        className="action-button"
      >
        {!this.state.loading ? (
          <>
            <Icon name={this.props.buttonIcon} />
            &nbsp;{this.props.buttonText}
          </>
        ) : (
          <Icon loading name="spinner" />
        )}
      </Button>
    );
  }
}

export default withFirestore()(withCompany()(withModal(ModalListComponent)));
