import React from 'react';
import { withRouter } from 'react-router';
import { Container, Segment, Header } from 'semantic-ui-react';

import ComponentContext from '../../context';

import EditComponent from '../../../EditComponent';

class Edit extends React.Component {
  static contextType = ComponentContext;

  constructor(props) {
    super(props);

    this.state = {
      saved: false,
      editing: null
    };

    this.editFormRef = React.createRef();
  }

  componentWillMount() {
    if (this.context.state.data[this.props.match.params.id]) {
      this.setState({
        editing: this.context.state.data[this.props.match.params.id]
      });
    } else {
      this.context.actions.get(this.props.match.params.id).then(res => {
        this.setState({ editing: res });
      });
    }
  }

  onSubmit = async data => {
    return this.context.actions
      .update(this.props.match.params.id, data)
      .then(res => {
        this.setState({ saved: true });
      });
  };

  render = () => {
    const { loading } = this.context.state;
    const { model } = this.props;

    return (
      <Container>
        <Segment.Group>
          <Segment>
            <Header as="h3" textAlign="center">{`Editar ${
              model.name ? model.name : ''
            }`}</Header>
          </Segment>
          <Segment>
            {this.state.editing && (
              <EditComponent
                ref={this.editFormRef}
                data={this.state.editing}
                model={model}
                loading={loading}
                onSubmit={this.onSubmit}
                onStartEditing={this.onFormStartEditing}
                saved={this.state.saved}
                editing={true}
                error={this.context.state.error}
              />
            )}
          </Segment>
        </Segment.Group>
      </Container>
    );
  };
}

export default withRouter(Edit);
