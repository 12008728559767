import * as EventModel from './EventModel';
import { Files } from './types';
import AccidentPDF from '../components/AccidentPDF';

const type = {
  name: 'type',
  caption: 'Tipo',
  type: 'Select',
  defaultValue: 'leve',
  editable: true,
  show: true,
  width: 3,
  options: [
    {
      key: '1',
      value: 'leve',
      text: 'Leve'
    },
    {
      key: '2',
      value: 'moderado',
      text: 'Moderado'
    },
    {
      key: '3',
      value: 'grave',
      text: 'Grave'
    },
    {
      key: '4',
      value: 'muygrave',
      text: 'Muy Grave'
    }
  ]
};

const event = {
  name: 'event',
  caption: 'Evento',
  type: 'Select',
  defaultValue: 'accidente',
  editable: true,
  show: true,
  width: 3,
  options: [
    {
      key: '1',
      value: 'accidente',
      text: 'Accidente'
    },
    {
      key: '2',
      value: 'incidente',
      text: 'Incidente'
    },
    {
      key: '3',
      value: 'enfermedad',
      text: 'Enfermedad Profesional'
    }
  ]
};

const AccidentModel = {
  ...EventModel,
  defaultSearchField: type,
  filterFields: [...EventModel.filterFields, type],
  name: 'accidente',
  actionComponents: [AccidentPDF],
  view: {
    ...EventModel.view,
    files: Files('accidents', true),
    type,
    event,
    responsable: {
      ...EventModel.view.responsable,
      validate: () => ({ valid: true })
    }
  }
};

export default AccidentModel;
