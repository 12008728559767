import React from 'react';
import { Form } from 'semantic-ui-react';

interface ISelectFieldProps {
  field: any;
  onChange: any;
  data: any
}

const SelectField: React.SFC<ISelectFieldProps> = ({ field, onChange, data }) => {
  return(
    <Form.Select
      placeholder={field.caption}
      name={field.name}
      options={field.options}
      value={field.viewParser ? field.viewParser(data) : data }
      onChange={onChange}
      search
    />
  )
}

export default SelectField;