import React, { createContext } from 'react';
import { Modal, Button, ModalProps } from 'semantic-ui-react';

type ModalState = {
  open: boolean;
  onAccept: undefined;
  onCancel: undefined;
} & ModalProps;

const initialState: ModalState = {
  open: false,
  title: '',
  content: '',
  size: 'small',
  onAccept: undefined,
  onCancel: undefined
};

const actions = {
  showModal: (
    options: {
      title?: string;
      content: any;
      onAccept?: () => any;
      onCancel?: () => any;
    } & ModalProps
  ) => {},
  closeModal: () => {}
};

export const ModalContext = createContext({
  state: initialState,
  actions
});

export type InjectedModalProps = {
  modal: typeof initialState & typeof actions;
};

export class ModalProvider extends React.Component {
  state: ModalState = initialState;

  showModal = ({ title, content, onAccept, onCancel, size, ...rest }) => {
    this.setState({
      open: true,
      title,
      content,
      size: size ? size : 'mini',
      onAccept,
      onCancel,
      ...rest
    });
  };

  closeModal = () => {
    this.setState({ open: false });
  };

  render() {
    return (
      <ModalContext.Provider
        value={{
          state: this.state,
          actions: {
            showModal: this.showModal,
            closeModal: this.closeModal
          }
        }}
      >
        {this.props.children}
        <ModalContext.Consumer>
          {({
            state: { title, content, open, onAccept, onCancel, size, ...rest }
          }) => (
            <Modal
              {...rest}
              size={size}
              open={open}
              onClose={onCancel ? onCancel : this.closeModal}
              closeOnDocumentClick
              closeOnEscape
            >
              {title && <Modal.Header>{title}</Modal.Header>}
              <Modal.Content scrolling>{content}</Modal.Content>
              {(onAccept || onCancel) && (
                <Modal.Actions>
                  {onCancel && (
                    <Button negative onClick={onCancel}>
                      No
                    </Button>
                  )}
                  {onAccept && (
                    <Button positive icon="checkmark" onClick={onAccept} />
                  )}
                </Modal.Actions>
              )}
            </Modal>
          )}
        </ModalContext.Consumer>
      </ModalContext.Provider>
    );
  }
}

export default ModalProvider;
