import * as React from 'react';
// Components
import Dashboard from '../../components/Dashboard';
import ABM from '../../components/ABM';
import * as StockModel from '../../models/StockModel';
import * as EppModel from '../../models/EppModel';
import * as PayrollModel from '../../models/PayrollModel';
import * as CompanyModel from '../../models/CompanyModel';
import * as ProviderModel from '../../models/ProviderModel';
import * as ItemModel from '../../models/ItemModel';
import * as BlueprintModel from '../../models/BlueprintModel';
import * as ClearanceModel from '../../models/ClearanceModel';
import * as ElementModel from '../../models/ElementModel';
import * as JobModel from '../../models/JobModel';
// import AccidentModel from '../../models/AccidentModel';

const routes = [
  {
    path: '',
    title: 'Dashboard',
    description: '',
    icon: 'dashboard',
    component: Dashboard,
    mainNavigation: true,
    default: true
  },
  {
    path: 'companies',
    title: 'Compañias',
    subtitle: '"Administracion de compañias"',
    description: '',
    icon: 'building',
    component: props => (
      <ABM.Component
        {...props}
        collectionName="companies"
        model={CompanyModel}
        initialState={CompanyModel.initialState}
      />
    ),
    mainNavigation: true
  },
  {
    path: 'nomina',
    title: 'Nómina',
    subtitle: '"Registro de personal"',
    description: '',
    icon: 'users',
    component: props => (
      <ABM.Component
        {...props}
        collectionName="payrolls"
        model={PayrollModel}
        initialState={PayrollModel.initialState}
      />
    ),
    mainNavigation: true
  },
  /* {
    path: 'riesgos',
    title: 'Riesgos',
    subtitle:
      '"Un aviso de riesgo es de vital importancia para reducir los accidentes laborales y preservar la salud de nuestros empleados"',
    description: '',
    icon: 'warning sign',
    component: props => (
      <ABM.Component
        {...props}
        collectionName="risks"
        model={RiskModel}
        initialState={RiskModel.initialState}
      />
    ),
    mainNavigation: true
  }, */
  /* {
    path: 'accidentes',
    title: 'Accidentes',
    subtitle:
      '"Un seguimiento de nuestros accidentes, con un posterior analisis nos permite reducir potencialmente los mismos"',
    description: '',
    icon: 'first aid',
    component: props => (
      <ABM.Component
        {...props}
        collectionName="accidents"
        model={AccidentModel}
        initialState={AccidentModel.initialState}
      />
    ),
    mainNavigation: true
  }, */
  {
    path: 'epp',
    title: 'EPP',
    subtitle:
      '"La reducción de los accidentes comienza desde la entrega de los elementos de protección personal"',
    description: '',
    icon: 'shield alternate',
    component: props => (
      <ABM.Component
        {...props}
        collectionName="epp"
        model={EppModel}
        initialState={EppModel.initialState}
      />
    ),
    mainNavigation: true
  },
  {
    path: 'stock',
    title: 'Stock',
    subtitle:
      '"Un continuo seguimiento de nuestro inventario, es de vital importancia para mantener un nivel optimo del mismo reduciendo asi los costos"',
    description: '',
    icon: 'archive',
    component: props => (
      <ABM.Component
        {...props}
        collectionName="stock"
        model={StockModel}
        initialState={StockModel.initialState}
      />
    ),
    mainNavigation: true
  },
  {
    path: 'items',
    title: 'Items',
    subtitle: '"Items"',
    description: '',
    icon: 'tag',
    component: props => (
      <ABM.Component
        {...props}
        collectionName="items"
        model={ItemModel}
        initialState={ItemModel.initialState}
      />
    ),
    mainNavigation: true
  },
  {
    path: 'elements',
    title: 'Tipos de Elementos',
    description: '',
    icon: 'briefcase',
    component: props => (
      <ABM.Component
        {...props}
        collectionName="elements"
        model={ElementModel}
        initialState={ElementModel.initialState}
      />
    ),
    mainNavigation: true
  },
  {
    path: 'providers',
    title: 'Proveedores',
    subtitle: '',
    description: '',
    icon: 'truck',
    component: props => (
      <ABM.Component
        {...props}
        collectionName="providers"
        model={ProviderModel}
        initialState={ProviderModel.initialState}
      />
    ),
    mainNavigation: true
  },
  {
    path: 'planos',
    title: 'Planos',
    description: '',
    icon: 'map',
    component: props => (
      <ABM.Component
        {...props}
        collectionName="blueprints"
        model={BlueprintModel}
        initialState={BlueprintModel.initialState}
      />
    ),
    mainNavigation: true
  },
  {
    path: 'capacitaciones',
    title: 'Capacitaciones',
    subtitle:
      '"Una reducción del indice de accidentes comienza con un buen plan de capacitaciones"',
    description: '',
    icon: 'book',
    component: () => <div>Trainings Component</div>,
    mainNavigation: true
  },
  {
    path: 'facturas',
    title: 'Facturas',
    description: '',
    icon: 'money bill alternate',
    component: () => <div>Bills Component</div>,
    mainNavigation: true
  },
  {
    path: 'habilitaciones',
    title: 'Habilitaciones',
    description: '',
    icon: 'certificate',
    component: props => (
      <ABM.Component
        {...props}
        collectionName="clearances"
        model={ClearanceModel}
        initialState={ClearanceModel.initialState}
      />
    ),
    mainNavigation: true
  },
  {
    path: 'jobs',
    title: 'Puestos',
    subtitle:
      '"Define los puestos dentro de la comañía y sus elementos de protección necesarios"',
    description: '',
    icon: 'male',
    component: props => (
      <ABM.Component
        {...props}
        collectionName="jobs"
        model={JobModel}
        initialState={JobModel.initialState}
      />
    ),
    mainNavigation: true
  }
];

export default routes;
