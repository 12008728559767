import React from 'react';
import { Timestamp, NotEmpty } from './types';
import CollectionList from '../components/CollectionList';
import DocumentParser from '../components/DocumentParser';

export const initialState = [];

export const view = {
  id: {
    name: 'id',
    caption: 'id',
    type: 'Text',
    defaultValue: '',
    editable: false,
    show: false
  },
  issueDate: {
    ...Timestamp,
    name: 'issueDate',
    caption: 'Fecha creacion',
    type: 'Timestamp',
    editable: false,
    show: false
  },
  type: {
    name: 'type',
    caption: 'Tipo',
    type: 'Component',
    defaultValue: '',
    editable: true,
    show: true,
    searchInCollection: 'elements',
    searchFieldsInCollection: ['name'],
    validate: value => NotEmpty.validate(value),
    component: props => (
      <CollectionList {...props} collectionName="elements" fields={['name']} />
    ),
    viewParser: (document = null) => (
      <DocumentParser
        document={document}
        collectionName="elements"
        fields={['name']}
      />
    ),
    saveParser: value => ({ id: value.id })
  },
  name: {
    name: 'name',
    caption: 'Nombre',
    type: 'Text',
    defaultValue: '',
    editable: true,
    show: true,
    validate: value => NotEmpty.validate(value)
  },
  description: {
    name: 'description',
    caption: 'Modelo',
    type: 'Text',
    defaultValue: '',
    editable: true,
    show: true
  },
  brand: {
    name: 'brand',
    caption: 'Marca',
    type: 'Text',
    defaultValue: '',
    editable: true,
    show: true
  },
  verification: {
    name: 'verification',
    caption: 'Posee verificación',
    type: 'Select',
    defaultValue: 'si',
    editable: true,
    show: true,
    width: 3,
    options: [
      {
        key: '1',
        value: 'si',
        text: 'Si'
      },
      {
        key: '2',
        value: 'no',
        text: 'No'
      }
    ]
  }
};

export const defaultSearchField = view.name;
export const filterFields = [view.name];
