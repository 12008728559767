import * as React from 'react';
import withFirestore from '../../hoc/firestore/withFirestore';

import SelectField from '../FormComponent/SelectField';
import { withCompany } from '../../hoc/company';
import { InjectedCompanyProps } from '../../hoc/company/provider';

interface ICollectionListProps {
  state?: any;
  actions?: any;
  onChange: any;
  data: any;
  collectionName: string;
  model: any;
  fields: string[];
}

interface ICollectionListState {}

class CollectionList extends React.Component<
  ICollectionListProps & InjectedCompanyProps,
  ICollectionListState
> {
  componentWillMount() {
    this.props.actions.fetch(
      `companies/${this.props.company.state.selectedCompany.id}`,
      this.props.collectionName
    );
  }

  onChange = (e, { value }) => {
    this.props.onChange(this.props.state[this.props.collectionName][value]);
  };

  render() {
    const { state, data, collectionName, model, fields } = this.props;

    const collection = state[collectionName];

    if (!collection) return null;

    const documents: any = Object.values(collection)
      ? Object.values(collection).map((document: any) => {
          const content = fields.reduce((acc, field) => {
            if (!acc) return document[field] ? document[field] : '';
            return `${acc}${document[field] ? ', ' + document[field] : ''}`;
          }, null);

          const item = {
            key: document.id,
            value: document.id,
            text: `${content}`
          };

          return item;
        })
      : [];

    const field = {
      name: model.name,
      caption: model.caption,
      options: documents
    };

    return (
      <SelectField
        onChange={this.onChange}
        field={field}
        data={data ? data.id : null}
      />
    );
  }
}

const filterState = (state: any) => {
  return state.data;
};

export default withFirestore(filterState)(withCompany()(CollectionList));
