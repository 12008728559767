
import React from 'react';
import { StorageContext } from './provider';

const withStorage = (mapState?: (state: any, props: any) => any, mapActions?: (actions: any) => any) => {
  return (Component: React.ComponentType) => {
    return (props: any) => {
      return (
        <StorageContext.Consumer>
          {
            ({state, actions}) => {

              return(
                <Component
                  {...props}
                  storage={{...state, ...actions}}
                />
              )
            }
          }
        </StorageContext.Consumer>
      )
    }
  }
}

export default withStorage;