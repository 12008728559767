import React from 'react';
import AccidentModel from '../../models/AccidentModel';
import ModalListComponent from '../ModalList/ModalListComponent';

function Accidents({ item }) {
  return (
    <ModalListComponent
      model={AccidentModel}
      document={item}
      collectionName="payrolls"
      field="accidents"
      buttonText="Accidentes"
      buttonIcon="first aid"
      buttonColor="red"
    />
  );
}

export default Accidents;
