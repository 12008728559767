import { createContext } from 'react';

const AuthContext = createContext({
  isAuthenticating: true,
  authenticated: false,
  user: {},
  accessToken: '',
  initiateLogin: () => {},
  handleAuthentication: () => {},
  logout: () => {},
  role: 'visitor',
  permissions: []
});

export default AuthContext;