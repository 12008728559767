import React from 'react';
import { PDFViewer } from '@react-pdf/renderer';
import PDFMarkup from './markup';

import { Button, Icon, Modal } from 'semantic-ui-react';
import { withFirestore, InjectedFirestoreProps } from '../../hoc/firestore';
import { withCompany } from '../../hoc/company';
import { InjectedCompanyProps } from '../../hoc/company/provider';

type AccidentPDFState = {
  loading: boolean;
  data: any;
  openModal: boolean;
};
class AccidentPDF extends React.Component<
  { item: any } & InjectedFirestoreProps & InjectedCompanyProps,
  AccidentPDFState
> {
  state = {
    loading: false,
    data: null,
    openModal: false
  };

  closeModal = () => {
    this.setState({ openModal: false });
  };

  showModal = () => {
    this.setState({ openModal: true });
    return;
  };

  getData = async () => {
    const {
      item: accident,
      company,
      state: { data },
      actions
    } = this.props;

    let payroll;
    let puesto;

    // Get payroll data from the accident
    if (data['payrolls'] && data['payrolls'][accident.responsable.id]) {
      payroll = data['payrolls'][accident.responsable.id];
    } else {
      payroll = await this.props.actions.get(
        `companies/${company.state.selectedCompany.id}`,
        'payrolls',
        accident.responsable.id
      );
    }

    // Get job data from the payroll
    if (data['jobs'] && data['jobs'][payroll.puesto.id]) {
      puesto = data['jobs'][payroll.puesto.id];
    } else {
      puesto = await this.props.actions.get(
        `companies/${company.state.selectedCompany.id}`,
        'jobs',
        payroll.puesto.id
      );
    }

    return {
      accident,
      payroll,
      puesto,
      company: company.state.selectedCompany
    };
  };

  handleOpenModal = async () => {
    this.setState({ loading: true }, () => {
      this.getData().then(data => {
        this.setState(
          {
            data,
            loading: false
          },
          () => {
            this.showModal();
          }
        );
      });
    });
  };

  render() {
    return (
      <>
        <Button
          size="mini"
          icon
          onClick={this.handleOpenModal}
          className="action-button"
        >
          {!this.state.loading ? (
            <>
              <Icon name="file pdf" />
              Reporte de Accidente
            </>
          ) : (
            <Icon loading name="spinner" />
          )}
        </Button>
        <Modal
          size="large"
          open={this.state.openModal}
          onClose={this.closeModal}
        >
          <Modal.Content>
            <PDFViewer width="100%" height="500px">
              <PDFMarkup data={this.state.data} />
            </PDFViewer>
          </Modal.Content>
        </Modal>
      </>
    );
  }
}

export default withFirestore()(withCompany()(AccidentPDF));
