import React from 'react';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import { withCompany } from '../../hoc/company';
import PropTypes from 'prop-types';

// Components
import Footer from '../../components/Footer';
import Content from '../../components/Content';
import SideMenu from '../../components/SideMenu';
import Header from '../../components/Header';

// Routes
import routes from './routes';

// Styles
import './style.css';

const social = [
  {
    name: 'Facebook',
    icon: 'fa-facebook-square',
    link: 'https://www.facebook.com'
  },
  {
    name: 'Twitter',
    icon: 'fa-twitter',
    link: 'https://www.twitter.com'
  }
];

class DashboardPage extends React.Component {
  render = () => {
    const {
      location,
      match,
      company: {
        state: { selectedCompany }
      }
    } = this.props;

    const showCompanyName = !location.pathname.includes('companies');

    return (
      <div className="dashboard-page">
        <div className="header">
          <Header
            title="Dashboard"
            routes={
              selectedCompany
                ? routes
                : routes.filter(route => route.path === 'companies')
            }
            options={
              selectedCompany
                ? [
                    {
                      title: 'Menu',
                      options: routes,
                      component: <SideMenu routes={routes} />
                    }
                  ]
                : []
            }
          />
        </div>
        <div
          className="dash-wrapper"
          style={{
            minHeight: '100vh',
            padding: '1em 0em',
            background: `linear-gradient(rgba(0, 0, 0, 0.45),rgba(0, 0, 0, 0.45)), url('../img/background-1.jpg')`,
            backgroundSize: 'cover'
          }}
        >
          {// No selected company => redirect to /companies to select one
          !location.pathname.includes('companies') && !selectedCompany && (
            <Redirect to={`${match.path}/companies`} />
          )}
          <Switch>
            {routes.map(route => {
              return (
                <Route
                  key={route.path}
                  exact={route.default}
                  path={`${match.path}/${route.path}`}
                  render={props => {
                    return (
                      <div className="dash-content">
                        <Content
                          title={`${
                            selectedCompany && showCompanyName
                              ? selectedCompany.name.toUpperCase() + ':'
                              : ''
                          } ${route.title}`}
                          icon={route.icon}
                          subtitle={route.subtitle}
                          description={route.description}
                        >
                          <route.component {...props} />
                        </Content>
                      </div>
                    );
                  }}
                />
              );
            })}
            <Redirect to={`${match.path}/`} />
          </Switch>
        </div>
        <div className="footer">
          <Footer
            copyrigth="Copyright © 2019 NRagone. All rights reserved."
            social={social}
          />
        </div>
      </div>
    );
  };
}

DashboardPage.defaultProps = {
  logout: null
};

DashboardPage.propTypes = {
  match: PropTypes.shape({}).isRequired
};

export default withCompany()(withRouter(DashboardPage));
