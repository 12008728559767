import { shape, string } from 'prop-types';
import Validator from 'validator';

export const propsDefinition = shape({
  name: string,
  lastName: string,
  email: string,
  phone: string,
  message: string
});

export const defaultProps = {
  name: '',
  lastName: '',
  email: '',
  phone: '',
  message: '',
}

export const State = {};

export const view = {
    name: {
      name: 'name',
      caption: 'Nombre',
      type: 'Text',
      editable: true,
      show: true,
      width: 8,
      validate: (value) => (
        {
          valid: Validator.matches(value, /^([a-zA-Z])+(\s*[a-zA-Z])*$/gm),
          error: 'Nombre no válido'
        }
      )
    },
    lastName: {
        name: 'lastName',
        caption: 'Apellido',
        type: 'Text',
        editable: true,
        show: true,
        width: 8,
        validate: (value) => (
          {
            valid: Validator.matches(value, /^([a-zA-Z])+(\s*[a-zA-Z])*$/gm),
            error: 'Apellido no válido'
          }
        )
    },
    email: {
      name: 'email',
      caption: 'Email',
      type: 'Text',
      editable: true,
      show: true,
      width: 8,
      validate: (value) => (
        {
          valid: Validator.isEmail(value),
          error: 'Email no válido'
        }
      )
    },
    phone: {
      name: 'phone',
      caption: 'Teléfono',
      type: 'Text',
      editable: true,
      show: true,
      width: 8,
      validate: (value) => ({
        valid: !Validator.isEmpty(value),
        error: `Formato de teléfono no válido`
      })
    },
    message: {
      name: 'message',
      caption: 'Mensaje',
      label: 'Mensaje',
      placeholder: 'Dejanos tu consulta, un representante se pondrá en contacto contigo a la brevedad',
      type: 'TextArea',
      editable: true,
      show: true,
      validate: (value) => ({
        valid: !Validator.isEmpty(value),
        error: `El Mensaje no puede estar vacío`
      })
    }
}