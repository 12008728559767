import React from 'react';
import { shape, string } from 'prop-types';
import { Payroll } from './types';
import SignComponent from '../components/SignComponent';

import * as types from './types';

export const propsDefinition = shape({
  name: string,
  lastName: string,
  email: string,
  phone: string
});

export const initialState = [];

export const view = {
  id: {
    name: 'id',
    caption: 'id',
    type: 'Text',
    defaultValue: '',
    editable: false,
    show: false
  },
  issueDate: {
    ...types.Timestamp,
    name: 'issueDate',
    caption: 'Fecha registro',
    type: 'Timestamp',
    editable: true,
    show: true
  },
  tareaObservada: {
    name: 'tareaObservada',
    caption: 'Tarea Observada',
    type: 'Text',
    defaultValue: '',
    editable: true,
    show: true,
    validate: types.NotEmpty.validate
  },
  accionTomada: {
    name: 'accionTomada',
    caption: 'Accion Tomada',
    type: 'Text',
    defaultValue: '',
    editable: true,
    show: true
  },
  responsable: {
    name: 'responsable',
    caption: 'Responsable',
    type: 'Component',
    defaultValue: '',
    editable: false,
    show: true,
    ...Payroll
  },
  jefe: {
    name: 'jefe',
    caption: 'Jefe',
    type: 'Component',
    defaultValue: '',
    editable: true,
    show: false,
    ...Payroll
  },
  responsableAviso: {
    name: 'responsableAviso',
    caption: 'Responsable Aviso',
    type: 'Component',
    defaultValue: '',
    editable: true,
    show: false,
    ...Payroll
  },
  sector: {
    name: 'sector',
    caption: 'Sector',
    type: 'Text',
    defaultValue: '',
    editable: true,
    show: false
  },
  acto: {
    name: 'acto',
    caption: 'Acto o Condicion',
    type: 'Select',
    defaultValue: 'acto',
    editable: true,
    show: true,
    width: 3,
    options: [
      {
        key: '1',
        value: 'acto',
        text: 'Acto'
      },
      {
        key: '2',
        value: 'condicion',
        text: 'Condicion'
      }
    ]
  },
  observaciones: {
    name: 'observaciones',
    caption: 'Observaciones',
    type: 'Text',
    defaultValue: '',
    editable: true,
    show: false
  },
  signature: {
    name: 'signature',
    caption: 'Firma',
    type: 'Component',
    defaultValue: '',
    editable: true,
    show: true,
    width: 16,
    component: props => <SignComponent {...props} />,
    viewParser: base64Img =>
      base64Img ? (
        <img alt="signature" src={base64Img} width="50px" height="50px" />
      ) : (
        'Sin firma'
      )
  }
};

export const defaultSearchField = view.responsable;
export const filterFields = [
  view.responsable,
  view.jefe,
  view.responsableAviso
];
