import React from 'react';
import styles from '../../pdfStyles';
import { Page, Text, View, Document } from '@react-pdf/renderer';

const Content = ({ company, accident, payroll, puesto }) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <View style={styles.table}>
            <View style={styles.header}>
              <View style={[styles.row, styles.textCentered]}>
                <View style={[styles.column, styles.h2]}>
                  <Text>
                    Planilla de investigacion de accidentes de trabajo
                  </Text>
                  <Text>"company name here"</Text>
                </View>
              </View>
              <View style={styles.row}>
                <View style={styles.column}>
                  <Text>1) Evento (marca lo que corresponda)</Text>
                </View>
                <View style={styles.column}>
                  <Text>SySO.R06 Versión: 00 F. Rev.:30/07/2018</Text>
                </View>
              </View>
            </View>
            <View style={styles.tableBody}>
              <View style={styles.row}>
                <View style={styles.column}>
                  <Text>Nº de la Denuncia de Accidentes: </Text>
                </View>
              </View>
              <View style={styles.row}>
                <View style={styles.column}>
                  <View style={{ display: 'flex', flexDirection: 'row' }}>
                    <Text style={{ marginRight: '5px' }}>Accidente </Text>
                    <Text
                      style={[
                        styles.square,
                        accident.event === 'accidente' && styles.squareSelected
                      ]}
                    />
                  </View>
                </View>
                <View style={styles.column}>
                  <View style={{ display: 'flex', flexDirection: 'row' }}>
                    <Text style={{ marginRight: '5px' }}>Incidente </Text>
                    <Text
                      style={[
                        styles.square,
                        accident.event === 'incidente' && styles.squareSelected
                      ]}
                    />
                  </View>
                </View>
                <View style={styles.column}>
                  <View style={{ display: 'flex', flexDirection: 'row' }}>
                    <Text style={{ marginRight: '5px' }}>
                      Enfermedad Profesional{' '}
                    </Text>
                    <Text
                      style={[
                        styles.square,
                        accident.event === 'enfermedad' && styles.squareSelected
                      ]}
                    />
                  </View>
                </View>
              </View>
              <View style={styles.row}>
                <View style={styles.column}>
                  <Text>
                    Nombre del accidentado: {payroll.lastName}, {payroll.name}
                  </Text>
                </View>
                <View style={styles.column}>
                  <Text>CUIL: {payroll.CUIL}</Text>
                </View>
              </View>
              <View style={styles.row}>
                <View style={styles.column}>
                  <Text>Antiguedad en el puesto: </Text>
                </View>
                <View style={styles.column}>
                  <Text>Ocupación: {puesto.name}</Text>
                </View>
              </View>
            </View>
            <View style={styles.header}>
              <View style={styles.row}>
                <View style={styles.column}>
                  <Text>
                    2) CODIFICACION DE LOS ESTADOS DEL ACCIDENTE DE TRABAJO
                  </Text>
                </View>
              </View>
            </View>
            <View style={styles.tableBody}>
              <View style={styles.row}>
                <View style={[styles.column, { height: '50px' }]}>
                  <Text>(1) Zona del Cuerpo Afectada</Text>
                </View>
                <View style={styles.column}>
                  <Text>(2) Naturaleza de la lesión</Text>
                </View>
                <View style={styles.column}>
                  <Text>(3) Forma de Accidente</Text>
                </View>
                <View style={styles.column}>
                  <Text>(4) Agente Causante</Text>
                </View>
              </View>
            </View>
            <View style={styles.header}>
              <View style={styles.row}>
                <View style={styles.column}>
                  <Text>3) DATOS DEL ACCIDENTE</Text>
                </View>
              </View>
            </View>
            <View style={styles.tableBody}>
              <View style={styles.row}>
                <View style={[styles.column, { height: '50px' }]}>
                  <Text>Fecha:</Text>
                  <Text>
                    {accident.issueDate.toDate().toLocaleDateString()}
                  </Text>
                </View>
                <View style={styles.column}>
                  <Text>Hora:</Text>
                  <Text>
                    {accident.issueDate.toDate().toLocaleTimeString()}
                  </Text>
                </View>
                <View style={styles.column}>
                  <Text>Realizaba horas extra al momento del accidente:</Text>
                </View>
              </View>
              <View style={styles.row}>
                <View style={[styles.column, { height: '50px' }]}>
                  <Text>Turno habitual:</Text>
                </View>
                <View style={styles.column}>
                  <Text>TURNO:</Text>
                </View>
                <View style={styles.column}>
                  <Text>Horario habitual:</Text>
                </View>
                <View style={styles.column}>
                  <View style={{ display: 'flex', flexDirection: 'row' }}>
                    <Text style={{ marginRight: '5px' }}>SI </Text>
                    <Text style={styles.square} />
                    <Text style={{ marginLeft: '10px', marginRight: '5px' }}>
                      NO{' '}
                    </Text>
                    <Text style={styles.square} />
                  </View>
                </View>
              </View>
            </View>
            <View style={styles.header}>
              <View style={styles.row}>
                <View style={styles.column}>
                  <Text>4) DESCRIPCIÓN DEL ACCIDENTE</Text>
                </View>
              </View>
            </View>
            <View style={styles.tableBody}>
              <View style={styles.row}>
                <View style={[styles.column, { height: '200px' }]}>
                  <Text>{accident.tareaObservada}</Text>
                  <Text>{accident.observaciones}</Text>
                </View>
              </View>
            </View>
            <View style={styles.header}>
              <View style={styles.row}>
                <View style={styles.column}>
                  <Text>5) RESUMEN Y POSIBLES CAUSAS DEL ACCIDENTE</Text>
                </View>
              </View>
            </View>
            <View style={styles.tableBody}>
              <View style={styles.row}>
                <View style={[styles.column, { height: '180px' }]} />
              </View>
            </View>
          </View>
        </View>
      </Page>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <View style={{ display: 'table' }}>
            <View style={{ display: 'table-header-group' }}>
              <View style={{ display: 'table-row' }}>
                <View style={{ display: 'table-cell' }}>
                  <Text>Nº de CAUSA</Text>
                </View>
                <View style={{ display: 'table-cell' }}>
                  <Text>MEDIDAS CORRECTIVAS A IMPLEMENTAR</Text>
                </View>
                <View style={{ display: 'table-cell' }}>
                  <View style={{ display: 'table-row' }}>
                    <Text>FECHA</Text>
                  </View>
                  <View style={{ display: 'table-row' }}>
                    <View style={{ display: 'table-cell' }}>
                      <Text>COMPROMISO</Text>
                    </View>
                    <View style={{ display: 'table-cell' }}>
                      <Text>REAL</Text>
                    </View>
                  </View>
                </View>
              </View>
            </View>
            <View style={{ display: 'table-row' }}>
              <View style={{ display: 'table-cell' }}>
                <Text>1</Text>
              </View>
              <View style={{ display: 'table-cell' }}>
                <Text>1</Text>
              </View>
              <View style={{ display: 'table-cell' }}>
                <Text>1</Text>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

const PDFMarkup = (props: any) => <Content {...props.data} />;

export default PDFMarkup;
