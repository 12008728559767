import React from 'react';
import { firestore, functions } from 'firebase/app';
import 'firebase/firestore';
import 'firebase/functions';

const firestoreInitialState = {
  state: {
    clean: true,
    loading: false,
    data: {}
  },
  actions: {}
};

// Create context with initial values
export const FirestoreContext = React.createContext(firestoreInitialState);

interface IFirestoreProviderProps {
  uid: string;
}

export interface IFirestoreProviderState {
  clean: boolean;
  loading: boolean;
  error?: object;
  data: {
    [key: string]: {
      [key: string]: {};
    };
  };
}

export type InjectedFirestoreProps = {
  state: IFirestoreProviderState;
  actions: any;
};

// Create provider for data and actions in the current context
export class FirestoreProvider extends React.Component<
  IFirestoreProviderProps,
  IFirestoreProviderState
> {
  private db: firestore.Firestore;
  constructor(props: IFirestoreProviderProps) {
    super(props);

    this.state = firestoreInitialState.state;

    this.db = firestore();
    //this.collection = this.db.collection(`${props.parentCollection ? props.parentCollection : ''}/${props.collectionName}`);
  }

  // This adds the logged uid on for each document updated/created
  parseData = (data: any) => {
    return { ...data, uid: this.props.uid };
  };

  /**
   * Fetch documents within collection
   */
  fetch = async (
    parentPath = '',
    collectionName: string,
    filters?: {
      field: string;
      condition: firestore.WhereFilterOp;
      value: string;
    }
  ) => {
    console.log(`Fetching ${collectionName} ...`);
    this.setState({ loading: true });

    const baseProm = this.db
      .collection(`${parentPath}/${collectionName}`)
      .where('uid', '==', this.props.uid);

    const prom = filters
      ? baseProm.where(filters.field, filters.condition, filters.value)
      : baseProm;

    return await prom
      .get()
      .then(querySnapshot => {
        const docs = querySnapshot.docs.reduce((acc, doc) => {
          return {
            ...acc,
            [doc.id]: { ...doc.data(), id: doc.id, parentPath }
          };
        }, {});

        this.setState({
          clean: false,
          loading: false,
          data: {
            ...this.state.data,
            [collectionName]: docs
          }
        });

        return docs;
      })
      .catch(err => {
        this.setState({
          loading: false,
          error: err
        });
      });
  };

  /**
   * Get document
   */
  get = async (
    parentPath: string,
    collectionName: string,
    documentId: string
  ) => {
    console.log(`Getting ${collectionName}...`);
    this.setState({ loading: true });
    return await this.db
      .collection(`${parentPath}/${collectionName}`)
      .doc(documentId)
      .get()
      .then(doc => {
        if (doc.exists) {
          this.setState({
            loading: false,
            error: undefined,
            data: {
              ...this.state.data,
              [collectionName]: {
                ...this.state.data[collectionName],
                [doc.id]: { ...doc.data(), id: doc.id, parentPath }
              }
            }
          });

          return { ...doc.data(), id: doc.id, parentPath };
        }
      })
      .catch(err => {
        this.setState({
          loading: false,
          error: err
        });
      });
  };

  /**
   * Delete document
   */
  delete = async (
    parentPath: string,
    collectionName: string,
    documentId: string
  ) => {
    console.log(`Deleting ${parentPath}/${collectionName}/${documentId}...`);
    this.setState({ loading: true });
    return this.db
      .collection(`${parentPath}/${collectionName}`)
      .doc(documentId)
      .delete()
      .then(() => {
        const newData = this.state.data[collectionName];
        delete newData[documentId];
        this.setState({
          loading: false,
          error: undefined,
          data: { ...this.state.data, [collectionName]: newData }
        });
      })
      .catch(err => {
        console.log('error deleting...', err);
        this.setState({
          loading: false,
          error: err
        });
      });
  };

  /**
   * Create document
   */
  create = async (parentPath: string, collectionName: string, data: any) => {
    console.log(`Creating ${parentPath}/${collectionName} ...`, data);
    this.setState({ loading: true });
    const parsed = this.parseData(data);
    return this.db
      .collection(`${parentPath}/${collectionName}`)
      .add(parsed)
      .then(res => {
        const updatedCollection = {
          ...this.state.data[collectionName],
          [res.id]: { ...parsed, id: res.id, parentPath }
        };
        this.setState({
          loading: false,
          data: {
            ...this.state.data,
            [collectionName]: updatedCollection
          },
          error: undefined
        });
        return res.id;
      })
      .catch(err => {
        this.setState({
          loading: false,
          error: err
        });
      });
  };

  /**
   * Update document
   */
  update = async (
    parentPath: string,
    collectionName: string,
    documentId: string,
    data: any
  ) => {
    console.log(
      `Updating ${parentPath}/${collectionName}/${documentId} ...`,
      data
    );
    this.setState({ loading: true });

    const parsed = this.parseData(data);

    return this.db
      .collection(`${parentPath}/${collectionName}`)
      .doc(documentId)
      .update({ ...parsed, id: documentId, parentPath })
      .then(() => {
        const updatedDocument = { ...parsed, id: documentId, parentPath };
        const updatedCollection = {
          ...this.state.data[collectionName],
          [documentId]: updatedDocument
        };

        this.setState({
          loading: false,
          data: { ...this.state.data, [collectionName]: updatedCollection },
          error: undefined
        });
      })
      .catch(err => {
        this.setState({
          loading: false,
          error: err
        });
      });
  };

  /**
   * Search document
   */
  search = async (parentPath: string, collectionName: string, data: any) => {
    console.log(`Searching...`, parentPath, collectionName, data);
    this.setState({ loading: true });
    return this.db
      .collection(`${parentPath}/${collectionName}`)
      .orderBy(data.field)
      .startAt(data.value.toLowerCase())
      .endAt(data.value.toLowerCase() + '\uf8ff')
      .get()
      .then(res => {
        this.setState({
          loading: false,
          error: undefined
        });

        return res.docs.map(doc => ({ ...doc.data(), id: doc.id }));
      })
      .catch(err => {
        this.setState({
          loading: false,
          error: err
        });
      });
  };

  /**
   * Load data from CSV
   */
  loadBatch = async (parentPath: string, collectionName: string, data: any) => {
    console.log(`Loading ${parentPath}/${collectionName} ...`, data);
    this.setState({ loading: true });

    this.deleteCollection(`${parentPath}/${collectionName}`).then(res => {
      const batch = this.db.batch();

      Object.keys(data).forEach(key => {
        data[key] = this.parseData(data[key]);
        const docRef = this.db
          .collection(`${parentPath}/${collectionName}`)
          .doc(key);
        batch.set(docRef, data[key]);
      });

      return batch
        .commit()
        .then(res => {
          this.setState({
            loading: false,
            data: {
              ...this.state.data,
              [collectionName]: data
            },
            error: undefined
          });
        })
        .catch(err => {
          this.setState({
            loading: false,
            error: err
          });
        });
    });
  };

  deleteCollection = async path => {
    const deleteCollection = functions().httpsCallable('recursiveDelete');
    return await deleteCollection({ path })
      .then(res => {
        console.log(`Collection ${path} borrada`, res);
      })
      .catch(e => {
        console.log(`Error deleting collection `, e);
      });
  };

  clearStore = (keepCollections = []) => {
    console.log('Clear Store...');
    if (keepCollections.length) {
      this.setState({
        data: Object.keys(this.state.data).reduce((acc, collectionName) => {
          return {
            ...acc,
            [collectionName]:
              keepCollections.indexOf(collectionName) > -1
                ? this.state.data[collectionName]
                : null
          };
        }, {})
      });
    }
  };

  render() {
    return (
      <FirestoreContext.Provider
        value={{
          state: !this.props.uid ? firestoreInitialState.state : this.state,
          actions: {
            fetch: this.fetch,
            get: this.get,
            delete: this.delete,
            create: this.create,
            update: this.update,
            search: this.search,
            loadBatch: this.loadBatch,
            clearStore: this.clearStore
          }
        }}
      >
        {this.props.children}
      </FirestoreContext.Provider>
    );
  }
}

export default FirestoreProvider;
