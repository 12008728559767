import { Files, Timestamp } from './types';

export const initialState = [];

export const name = 'habilitación';

export const view = {
  id: {
    name: 'id',
    caption: 'id',
    type: 'Text',
    defaultValue: '',
    editable: false,
    show: false
  },
  issueDate: {
    name: 'issueDate',
    caption: 'Fecha desde',
    type: 'Timestamp',
    editable: true,
    show: true,
    ...Timestamp
  },
  expiryDate: {
    name: 'expiryDate',
    caption: 'Fecha hasta',
    type: 'Timestamp',
    editable: true,
    show: true,
    ...Timestamp
  },
  name: {
    name: 'name',
    caption: 'Nombre',
    type: 'Text',
    defaultValue: '',
    editable: true,
    show: true
  },
  description: {
    name: 'description',
    caption: 'Descripcion',
    type: 'Text',
    defaultValue: '',
    editable: true,
    show: true
  },
  files: Files('clearances')
};

export const defaultSearchField = view.name;
export const filterFields = [view.name];
