import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import {
  Container,
  Segment,
  Icon,
  Divider,
  Grid,
  Message
} from 'semantic-ui-react';
import { withFirestore } from '../../hoc/firestore';
import { withCompany } from '../../hoc/company';
import AreaChart from './components/AreaChart';
import PieChartComponent from './components/PieChart';

const FreqSelector = ({ selectedFreq, onClick }) => {
  const selectedColor = 'rgb(23,139,250,0.5)';
  return (
    <div>
      <button
        style={{ background: selectedFreq === 'day' ? selectedColor : 'none' }}
        onClick={() => onClick('day')}
      >
        Por día
      </button>
      <button
        style={{
          background: selectedFreq === 'isoWeek' ? selectedColor : 'none'
        }}
        onClick={() => onClick('isoWeek')}
      >
        Por semana
      </button>
      <button
        style={{
          background: selectedFreq === 'month' ? selectedColor : 'none'
        }}
        onClick={() => onClick('month')}
      >
        Por mes
      </button>
      <button
        style={{ background: selectedFreq === 'year' ? selectedColor : 'none' }}
        onClick={() => onClick('year')}
      >
        Por año
      </button>
    </div>
  );
};

const Accidents = ({ accidents }) => {
  const desc = accidents.sort((a, b) => {
    return b.issueDate.seconds - a.issueDate.seconds;
  });

  if (!desc.length) return null;

  const timeDiff = Math.abs(Date.now() - desc[0].issueDate.toDate().getTime());
  const dias = timeDiff / (1000 * 60 * 60 * 24);

  if (dias < 1) return <span>0</span>;

  return <span>{Math.floor(dias)}</span>;
};

const FreqParser = ({ freq }) => {
  switch (freq) {
    case 'day':
      return <span>Día</span>;
    case 'isoWeek':
      return <span>Semana</span>;
    case 'month':
      return <span>Mes</span>;
    case 'year':
      return <span>Año</span>;
    default:
      return <span />;
  }
};

class DashboardComponent extends React.Component {
  state = {
    data: {
      risks: [],
      accidents: [],
      groupedRisks: [],
      groupedAccidents: []
    },
    risks: {
      total: 0,
      freq: 'month',
      values: [],
      selectedValue: null,
      activeLabel: ''
    },
    accidents: {
      total: 0,
      freq: 'month',
      values: [],
      selectedValue: null,
      activeLabel: ''
    }
  };

  componentWillMount() {
    if (!this.props.state.data['payrolls']) {
      this.props.actions
        .fetch(
          `companies/${this.props.company.state.selectedCompany.id}`,
          'payrolls'
        )
        .then(() => {
          this.loadData();
        });
    } else {
      this.loadData();
    }
  }

  loadData = () => {
    const payrolls = this.props.state.data.payrolls;

    const data = Object.values(payrolls).reduce(
      (acc, payroll) => {
        return {
          risks: [...acc.risks, ...payroll.risks],
          accidents: [...acc.accidents, ...payroll.accidents]
        };
      },
      { risks: [], accidents: [] }
    );

    data.groupedRisks = this.groupByObjectKey(data.risks, 'type');
    data.groupedAccidents = this.groupByObjectKey(data.accidents, 'type');

    this.setState({ data }, () => {
      this.changeFreq(this.state.risks.freq, 'risks');
      this.changeFreq(this.state.accidents.freq, 'accidents');
    });
  };

  changeFreq = (freq, collectionName) => {
    const data = this.state.data[collectionName];

    const group = this.groupByDate(data, freq);

    this.setState({
      [collectionName]: {
        ...this.state[collectionName],
        selectedValue: null,
        total: data.length,
        freq: freq,
        count: group.length,
        values: group
      }
    });
  };

  groupByDate = (collection, by) => {
    collection = collection.sort((a, b) => {
      return a.issueDate.seconds - b.issueDate.seconds;
    });

    const groupedResults = _.groupBy(collection, document =>
      moment(document['issueDate'].toDate(), 'DD/MM/YYYY').startOf(by)
    );

    return Object.keys(groupedResults).map(key => {
      const d = new Date(key);
      return {
        values: groupedResults[key],
        name: d.toLocaleDateString(),
        count: groupedResults[key].length
      };
    });
  };

  groupByObjectKey = (collection, key) => {
    const grouped = _.groupBy(collection, key);
    return Object.keys(grouped).map(k => ({
      name: k ? k : 'otro',
      value: grouped[k].length
    }));
  };

  updatePie = (collectionName, value) => {
    if (!value) return;
    this.setState({
      [collectionName]: {
        ...this.state[collectionName],
        selectedValue: this.groupByObjectKey(
          value.activePayload[0].payload.values,
          'type'
        ),
        activeLabel: value.activeLabel
      }
    });
  };

  render() {
    const {
      risks: {
        total: totalRisks,
        selectedValue: selectedRisks,
        values: risks,
        freq: risksFreq,
        activeLabel: risksActiveLabel
      },
      accidents: {
        total: totalAccidents,
        selectedValue: selectedAccidents,
        values: accidents,
        freq: accidentsFreq,
        activeLabel: accidentsActiveLabel
      }
    } = this.state;

    return (
      <Container textAlign="center">
        <Segment>
          <Grid stackable>
            <Grid.Row>
              <Grid.Column>
                <Message info>
                  <Message.Header>
                    Días sin accidentes:{' '}
                    <Accidents accidents={this.state.data.accidents} />
                  </Message.Header>
                </Message>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={2} verticalAlign="top" textAlign="center">
              <Grid.Column>
                {/* Accidents */}
                {/* AreaChart */}
                <Grid.Row>
                  <Grid.Column>
                    <h3>
                      <Icon name="first aid" color="red" size="small" />{' '}
                      Accidentes
                    </h3>
                    <Divider />
                    <FreqSelector
                      selectedFreq={accidentsFreq}
                      onClick={freq => this.changeFreq(freq, 'accidents')}
                    />
                    <AreaChart
                      color="#d82a2f"
                      data={accidents}
                      onClick={value => this.updatePie('accidents', value)}
                    />
                  </Grid.Column>
                </Grid.Row>
                {/* PieChart */}
                <Grid.Row>
                  <Grid.Column>
                    <Grid stackable>
                      <Grid.Row columns={2} verticalAlign="top">
                        {totalAccidents && (
                          <Grid.Column>
                            <PieChartComponent
                              data={this.state.data['groupedAccidents']}
                            />
                            <h3 style={{ margin: 0 }}>
                              Total: {totalAccidents}
                            </h3>
                          </Grid.Column>
                        )}
                        {selectedAccidents && (
                          <Grid.Column>
                            <PieChartComponent
                              data={selectedAccidents ? selectedAccidents : []}
                            />
                            <h3 style={{ margin: 0 }}>
                              Total: {selectedAccidents.length}
                            </h3>
                            <h4 style={{ margin: 0 }}>
                              <FreqParser freq={accidentsFreq} />
                            </h4>
                            <h4 style={{ margin: 0 }}>
                              {accidentsActiveLabel}
                            </h4>
                          </Grid.Column>
                        )}
                      </Grid.Row>
                    </Grid>
                  </Grid.Column>
                </Grid.Row>
              </Grid.Column>
              <Grid.Column>
                {/* Risks */}
                {/* AreaChart */}
                <Grid.Row>
                  <Grid.Column>
                    <h3>
                      <Icon name="warning sign" color="orange" size="small" />{' '}
                      Riesgos
                    </h3>
                    <Divider />
                    <FreqSelector
                      selectedFreq={risksFreq}
                      onClick={freq => this.changeFreq(freq, 'risks')}
                    />
                    <AreaChart
                      color="#f0712c"
                      data={risks}
                      onClick={value => this.updatePie('risks', value)}
                    />
                  </Grid.Column>
                </Grid.Row>
                {/* PieChart */}
                <Grid.Row>
                  <Grid.Column>
                    <Grid stackable>
                      <Grid.Row columns={2} verticalAlign="top">
                        {totalRisks && (
                          <Grid.Column>
                            <PieChartComponent
                              data={this.state.data['groupedRisks']}
                            />
                            <h3 style={{ margin: 0 }}>Total: {totalRisks}</h3>
                          </Grid.Column>
                        )}
                        {selectedRisks && (
                          <Grid.Column>
                            <PieChartComponent
                              data={selectedRisks ? selectedRisks : []}
                            />
                            <h3 style={{ margin: 0 }}>
                              Total: {selectedRisks.length}
                            </h3>
                            <h4 style={{ margin: 0 }}>
                              <FreqParser freq={risksFreq} />
                            </h4>
                            <h4 style={{ margin: 0 }}>{risksActiveLabel}</h4>
                          </Grid.Column>
                        )}
                      </Grid.Row>
                    </Grid>
                  </Grid.Column>
                </Grid.Row>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      </Container>
    );
  }
}

export default withFirestore()(withCompany()(DashboardComponent));
