import { createContext } from 'react';

const ComponentContext = createContext({
  collectionName: '',
  model: {},
  state: {data: {}, loading: false, error: undefined},
  actions: {},
  routes: []
});

export default ComponentContext;