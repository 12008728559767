import React from 'react';
import CsvParse from '@vtex/react-csv-parse';
import { Button } from 'semantic-ui-react';
import { withModal } from '../../hoc/modal';

class ImportCSV extends React.Component {
  constructor(props) {
    super(props);

    this.inputRef = React.createRef();
  }

  onCSVLoaded = (data) => {
    this.props.modal.showModal({
      title: "Confirmar",
      content: "Todos los datos se reemplazaran, seguro desea continuar?",
      onAccept: () => {
        this.props.onLoad(data);
        this.inputRef.current.value = "";
        this.props.modal.closeModal();
      },
      onCancel: () => { this.props.modal.closeModal(); }
    });
  }

  onButtonClick = () => {
    this.inputRef.current.click();
  }

  render() {
    const { model } = this.props;
    const keys = Object.values(model).map(field => field.name);

    return (
      <CsvParse
        keys={keys}
        onDataUploaded={this.onCSVLoaded}
        onError={this.handleError}
        render={onChange => 
          <>
            <Button
              primary
              type="button"
              color="green"
              size='small'
              content='Importar CSV'
              icon='upload'
              labelPosition='right'
              onClick={this.onButtonClick} />
            <input ref={this.inputRef} type="file" onChange={onChange} style={{display: "none"}} />
          </>
        }
      />
    );
  }
}

export default withModal(ImportCSV);