import { Files, Timestamp } from './types';

export const initialState = [];

export const name = 'plano';

export const view = {
  id: {
    name: 'id',
    caption: 'id',
    type: 'Text',
    defaultValue: '',
    editable: false,
    show: false
  },
  issueDate: {
    name: 'issueDate',
    caption: 'Fecha',
    type: 'Timestamp',
    editable: false,
    show: false,
    ...Timestamp
  },
  area: {
    name: 'area',
    caption: 'Area',
    type: 'Text',
    defaultValue: '',
    editable: true,
    show: true
  },
  description: {
    name: 'description',
    caption: 'Descripcion',
    type: 'Text',
    defaultValue: '',
    editable: true,
    show: true
  },
  files: Files('blueprints')
};

export const defaultSearchField = view.area;
export const filterFields = [view.area];
