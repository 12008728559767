import React, { createContext } from 'react';
import { firestore } from 'firebase/app';
import 'firebase/firestore';
import { withFirestore, InjectedFirestoreProps } from '../firestore';
import { ModalProvider, withModal, InjectedModalProps } from '../modal';
import { Company } from '../../types/Company';
import { StorageProvider } from '../storage';

interface ICompanyProviderProps {
  uid: string;
}

interface ICompanyProviderState {
  userCompanies: Company[];
  selectedCompany: Company;
  loading: boolean;
  showModal: boolean;
  firstLoad: boolean;
}

const initialState = {
  userCompanies: [],
  selectedCompany: null,
  loading: true,
  showModal: false,
  firstLoad: true
};

export const CompanyContext = createContext({
  state: initialState,
  actions: {}
});

export type InjectedCompanyProps = {
  company: {
    state: ICompanyProviderState;
    actions: any;
  };
};

export class CompanyProvider extends React.Component<
  ICompanyProviderProps & InjectedModalProps & InjectedFirestoreProps,
  ICompanyProviderState
> {
  private db: firestore.Firestore;
  private collection: firestore.CollectionReference;
  private companiesListener: () => any;

  constructor(props) {
    super(props);

    this.state = initialState;
    this.db = firestore();
    this.collection = this.db.collection('companies');
  }

  componentDidMount() {
    // Fetch user companies and load the state
    this.setState({ loading: true });
    const uid = this.props.uid;
    console.log(`Getting companies for user ${uid} ...`);
    this.onCompaniesListener(uid);
  }

  componentWillUnmount() {
    // Stop listening to changes
    this.companiesListener();
  }

  onCompaniesListener = uid => {
    this.companiesListener = this.collection
      .where('uid', '==', uid)
      .onSnapshot(querySnapshot => {
        if (querySnapshot.empty) {
          this.setState({ userCompanies: [], loading: false });
        } else {
          // Fill in user companies
          this.setUserCompanies(querySnapshot.docs);
        }
      });
  };

  setUserCompanies = (docs: firestore.DocumentData) => {
    const userCompanies: Company[] = docs.reduce((acc, doc) => {
      return { ...acc, [doc.id]: { ...doc.data(), id: doc.id } };
    }, {});

    const selectedCompany = Object.values(docs).find(
      doc => doc.data().selected
    );

    if (!selectedCompany) {
      this.props.modal.showModal({
        title: 'No se seleccionó ninguna compañia',
        content: <p>Debe seleccionar una compañia para poder operar</p>,
        onAccept: () => this.props.modal.closeModal()
      });
    }

    if (!this.state.firstLoad) {
      this.props.actions.clearStore(['companies']);
    }

    this.setState({
      ...this.state,
      loading: false,
      firstLoad: false,
      userCompanies,
      selectedCompany: selectedCompany
        ? { ...selectedCompany.data(), id: selectedCompany.id }
        : undefined,
      showModal: selectedCompany ? false : true
    });
  };

  render() {
    return (
      <CompanyContext.Provider
        value={{
          state: this.state,
          actions: {}
        }}
      >
        <StorageProvider
          uid={this.props.uid}
          companyId={
            this.state.selectedCompany ? this.state.selectedCompany.id : ''
          }
        >
          <ModalProvider>{this.props.children}</ModalProvider>
        </StorageProvider>
      </CompanyContext.Provider>
    );
  }
}

export default withFirestore()(withModal(CompanyProvider));
