import React from 'react';
import { CompanyContext } from './provider';
import { Container, Dimmer, Loader } from 'semantic-ui-react';

const withCompany = (
  mapState?: (state: any, props: any) => any,
  mapActions?: (actions: any) => any
) => {
  return (Component: React.ComponentType) => {
    return (props: any) => {
      return (
        <CompanyContext.Consumer>
          {({ state, actions }) => {
            if (state.loading) {
              return (
                <Container>
                  <Dimmer active>
                    <Loader>Cargando compañías</Loader>
                  </Dimmer>
                </Container>
              );
            }

            return (
              <Component
                {...props}
                company={{
                  state: mapState ? mapState(state, props) : state,
                  actions: mapActions ? mapActions(actions) : actions
                }}
              />
            );
          }}
        </CompanyContext.Consumer>
      );
    };
  };
};

export default withCompany;
