import React from 'react';
import { AuthContext } from '../../context';
import { Button } from 'semantic-ui-react';

const Logout = props => (
  <AuthContext.Consumer>
    {({ logout }) => {
      if (props.children) return <span onClick={logout}>{props.children}</span>;

      return <Button onClick={logout}>Logout</Button>;
    }}
  </AuthContext.Consumer>
);

export default Logout;
