import React, { createContext } from 'react';
import { storage } from 'firebase/app';
import 'firebase/storage';

interface IStorageProviderProps {
  uid: string;
  companyId: string;
}

interface IStorageProviderState {
  uploading: boolean;
}

const initialState = {
  uploading: false
};

export const StorageContext = createContext({
  state: initialState,
  actions: {}
});

export class StorageProvider extends React.Component<
  IStorageProviderProps,
  IStorageProviderState
> {
  private storage: storage.Storage;
  constructor(props: IStorageProviderProps) {
    super(props);

    this.state = initialState;

    this.storage = storage();
  }

  uploadFile = async ({ path, file }) => {
    console.log('Upload ', file);
    return this.storage
      .ref(`companies/${this.props.companyId}/${path}/${file.name}`)
      .put(file.file)
      .then(snap => {
        return snap.ref.getDownloadURL().then(url => {
          return {
            name: file.name,
            type: file.type,
            preview: file.preview,
            downloadURL: url
          };
        });
      });
  };

  deleteFile = async ({ path, file }) => {
    console.log('Delete file ', file);
    return this.storage
      .ref(`companies/${this.props.companyId}/${path}/${file.name}`)
      .delete()
      .then(() => {
        return { error: false };
      })
      .catch(e => {
        return { error: e };
      });
  };

  render() {
    return (
      <StorageContext.Provider
        value={{
          state: this.state,
          actions: {
            uploadFile: this.uploadFile,
            deleteFile: this.deleteFile
          }
        }}
      >
        {this.props.children}
      </StorageContext.Provider>
    );
  }
}

export default StorageProvider;
