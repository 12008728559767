import React from 'react';
import { Dropdown, Form } from 'semantic-ui-react';
import provincesAndLocations from '../../data/provinces-locations.json';

type Location = {
  id: string;
  nombre: string;
  key: string;
  value: string;
  text: string;
};

type Province = {
  id: number;
  nombre: string;
  key: number;
  value: number;
  text: string;
};

type IProvincesSelectorState = {
  selectedProvince: Province;
  selectedLocation: Location;
  locations: Location[];
};

class ProvinceSelector extends React.Component<any, IProvincesSelectorState> {
  private initialProvinces: Province[];
  private initialLocations: Location[];

  constructor(props) {
    super(props);

    this.initialProvinces = this.parseProvinces(provincesAndLocations);
    this.initialLocations = this.parseLocations(
      provincesAndLocations[0].ciudades
    );

    this.state = {
      locations: this.initialLocations,
      selectedProvince: this.initialProvinces[0],
      selectedLocation: this.parseLocations([
        provincesAndLocations[0].ciudades[0]
      ])[0]
    };
  }

  componentWillMount = () => {
    if (this.props.data) {
      const { location, province } = this.props.data;

      const selectedProvince = provincesAndLocations.find(
        p => p.id === province.id
      );
      const selectedLocation = selectedProvince.ciudades.find(
        l => l.id === location.id
      );

      this.setState({
        selectedProvince: this.parseProvinces([selectedProvince])[0],
        selectedLocation: this.parseLocations([selectedLocation])[0],
        locations: this.parseLocations(selectedProvince.ciudades)
      });
    }
  };

  parseProvinces = provinces =>
    provinces.map(province => {
      return {
        id: province.id,
        nombre: province.nombre,
        key: province.id,
        value: province.id,
        text: province.nombre
      };
    });

  parseLocations = locations =>
    locations.map(location => {
      return {
        ...location,
        key: location.id,
        value: location.id,
        text: location.nombre
      };
    });

  handleProvinceChange = (e, { value: provinceId }) => {
    const province = provincesAndLocations.find(
      province => province.id === provinceId
    );

    const locations = this.parseLocations(province.ciudades);

    this.setState({
      selectedProvince: this.parseProvinces([province])[0],
      selectedLocation: locations[0],
      locations
    });
  };

  handleLocationChange = (e, { value: locationId }) => {
    const selectedLocation = this.state.locations.find(
      location => location.id === locationId
    );

    this.setState(
      {
        selectedLocation
      },
      () => {
        this.props.onChange({
          province: {
            id: this.state.selectedProvince.id,
            nombre: this.state.selectedProvince.nombre
          },
          location: {
            id: this.state.selectedLocation.id,
            nombre: this.state.selectedLocation.nombre
          }
        });
      }
    );
  };

  render() {
    return (
      <>
        <Form.Group inline>
          <Form.Field>
            <Dropdown
              placeholder="Seleccionar Provincia"
              search
              selection
              options={this.initialProvinces}
              onChange={this.handleProvinceChange}
              defaultValue={this.state.selectedProvince.id}
            />
          </Form.Field>
          <Form.Field>
            <LocationSelector
              options={this.state.locations}
              defaultValue={this.state.selectedLocation.id}
              onChange={this.handleLocationChange}
            />
          </Form.Field>
        </Form.Group>
      </>
    );
  }
}

const LocationSelector = ({ options, defaultValue, onChange }) => {
  return (
    <Dropdown
      placeholder="Seleccionar Localidad"
      search
      selection
      options={options}
      defaultValue={defaultValue}
      onChange={onChange}
    />
  );
};

export default ProvinceSelector;
