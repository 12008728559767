import React from 'react';
import SignaturePad from 'react-signature-canvas';
import { Button, Icon } from 'semantic-ui-react';
import './style.css';

interface SignComponentProps {
  data: string;
  onChange: (string) => any;
  sigPad: any;
}

class SignComponent extends React.Component<SignComponentProps> {
  sigPad: typeof SignaturePad.clear = {};

  constructor(props) {
    super(props);

    this.state = {
      trimmedDataURL: props.data ? props.data : null
    };
  }

  componentDidMount() {
    if (this.props.data) {
      this.sigPad.fromDataURL(this.props.data);
    }
  }

  clear = () => {
    this.sigPad.clear();
  };

  trim = () => {
    const trimmedDataURL = this.sigPad
      .getTrimmedCanvas()
      .toDataURL('image/png');
    this.props.onChange(trimmedDataURL);
  };

  render() {
    return (
      <div className="sign-component">
        <div className="pad">
          <SignaturePad
            penColor="green"
            clearOnResize={false}
            canvasProps={{ className: 'canvas' }}
            ref={ref => {
              this.sigPad = ref;
            }}
            onEnd={this.trim}
          />
        </div>
        <div className="buttons">
          <Button icon primary type="button" onClick={this.clear}>
            <Icon name="eraser" />
          </Button>
        </div>
      </div>
    );
  }
}

export default SignComponent;
