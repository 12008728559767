import { shape, string } from 'prop-types';
import * as types from './types';

export const propsDefinition = shape({
  name: string,
  lastName: string,
  phone: string,
});

export const initialState = [];

export const view = {
  id: {
    name: 'id',
    caption: 'id',
    type: 'Text',
    defaultValue: '',
    editable: false,
    show: false,
  },
  CUIT: {
    name: 'CUIT',
    caption: 'CUIT',
    type: 'Text',
    defaultValue: '',
    editable: true,
    show: true,
    //validate: (value) => ( types.CUIL.validate(value, 'CUIL no válido'))
  },
  name: {
    name: 'name',
    caption: 'Nombre',
    type: 'Text',
    defaultValue: '',
    editable: true,
    show: true,
    width: 4,
    validate: (value) => ( types.Name.validate(value, 'Nombre no válido') )
  },
  address: {
    name: 'address',
    caption: 'Domicilio',
    type: 'Text',
    defaultValue: '',
    editable: true,
    show: true,
  },
  email: {
    name: 'email',
    caption: 'Email',
    type: 'Text',
    defaultValue: '',
    editable: true,
    show: true,
    width: 4,
    validate: types.Email.validate,
  },
};

export const defaultSearchField = view.name;
export const filterFields = [view.name, view.CUIT];
