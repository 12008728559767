import React from 'react';
import PropTypes from 'prop-types';
import { firestore } from 'firebase/app';
import 'firebase/firestore';
import { Form, Checkbox } from 'semantic-ui-react';
import DatePicker, {
  setDefaultLocale,
  getDefaultLocale
} from 'react-datepicker';

import './style.css';

import SelectField from './SelectField';
import InlineError from '../Messages/InlineError';

class FormComponent extends React.Component {
  constructor(props) {
    super(props);

    setDefaultLocale(getDefaultLocale());

    this.state = {
      data: props.data,
      errors: {},
      loading: false,
      startEditing: false,
      changed: false
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.cleanForm) return { ...state, data: props.data };

    return state;
  }

  onComponentChange = (fieldName, data) => {
    this.setState({
      data: { ...this.state.data, [fieldName]: data },
      errors: {}
    });

    if (!this.state.startEditing) {
      this.enableSaveButton();
      this.props.onStartEditing();
      this.setState({ startEditing: true });
    }
  };

  onChange = (e, { name, value, type, checked }) => {
    if (type === 'checkbox') value = checked;

    this.setState({
      data: {
        ...this.state.data,
        [name]: type === 'text' ? value.toLowerCase() : value
      },
      errors: {}
    });

    if (!this.state.startEditing) {
      this.enableSaveButton();
      this.props.onStartEditing();
      this.setState({ startEditing: true });
    }
  };

  onDateChange = (date, fieldName) => {
    console.log(date);
    this.setState({
      data: {
        ...this.state.data,
        [fieldName]: firestore.Timestamp.fromDate(date)
      }
    });

    if (!this.state.startEditing) {
      this.enableSaveButton();
      this.props.onStartEditing();
      this.setState({ startEditing: true });
    }
  };

  onSubmit = () => {
    const errors = this.validate(this.state.data);
    this.setState({ errors });

    if (Object.keys(errors).length === 0) {
      this.props.onSubmit(this.state.data);
    }

    this.disableSaveButton();
    this.setState({ startEditing: false });
  };

  disableSaveButton = () => {
    this.setState({ changed: false });
  };

  enableSaveButton = () => {
    this.setState({ changed: true });
  };

  validate = data => {
    const errors = {};

    Object.keys(data).forEach(field => {
      if (
        this.props.model.view[field] &&
        this.props.model.view[field].validate
      ) {
        const result = this.props.model.view[field].validate(data[field]);

        if (!result.valid) errors[field] = result.error;
      }
    });
    return errors;
  };

  parseDataType = (field, data) => {
    let element;

    // TODO: Remover viewParse del value, se llama en cada cambio de valor, el view parser se deberia utilizar en la primer carga
    // a este componente ya deberia llegar la data parseada o parsearla al construirlo.
    switch (field.type) {
      case 'Text':
      case 'Number':
      case 'Email':
        element = (
          <Form.Field
            key={`field-${field.name}`}
            error={!!this.state.errors[field.name]}
            mobile={16}
            computer={field.width}
            className="form-field"
          >
            <Form.Input
              label={field.caption}
              type={field.type.toLowerCase()}
              disabled={!field.editable}
              name={field.name}
              value={
                field.viewParser
                  ? field.viewParser(this.state.data[field.name])
                  : this.state.data[field.name]
              }
              onChange={this.onChange}
              placeholder={field.placeholder ? field.placeholder : ''}
            />
            {this.state.errors[field.name] && (
              <InlineError text={this.state.errors[field.name]} />
            )}
          </Form.Field>
        );
        break;
      case 'Timestamp':
        element = (
          <Form.Field
            key={`field-${field.name}`}
            error={!!this.state.errors[field.name]}
            mobile={16}
            computer={field.width}
            className="form-field"
          >
            <label>{field.caption}</label>
            <DatePicker
              showTimeSelect
              todayButton={'Hoy'}
              selected={this.state.data[field.name].toDate()}
              timeFormat="HH:mm"
              timeIntervals={1}
              dateFormat="dd/MM/yyyy HH:mm"
              onChange={date => this.onDateChange(date, field.name)}
            />
            {this.state.errors[field.name] && (
              <InlineError text={this.state.errors[field.name]} />
            )}
          </Form.Field>
        );
        break;
      case 'Select':
        element = (
          <Form.Field
            key={`field-${field.name}`}
            error={!!this.state.errors[field.name]}
            mobile={16}
            computer={field.width}
            className="form-field"
          >
            <label htmlFor={field.name}>{field.caption}</label>
            <SelectField
              key={`field-${field.name}`}
              field={field}
              onChange={this.onChange}
              data={data}
            />
          </Form.Field>
        );
        break;
      case 'TextArea':
        element = (
          <Form.TextArea
            error={!!this.state.errors[field.name]}
            mobile={16}
            computer={field.width}
            label={field.label ? field.label : ''}
            placeholder={field.placeholder ? field.placeholder : ''}
            className="form-field"
          />
        );
        break;
      case 'Checkbox':
        element = (
          <Form.Field
            error={!!this.state.errors[field.name]}
            key={`field-${field.name}`}
            mobile={16}
            computer={field.width}
            className="form-field"
          >
            <label htmlFor={field.name}>{field.caption}</label>
            <Checkbox
              toggle
              key={`field-${field.name}`}
              name={field.name}
              defaultChecked={this.state.data[field.name]}
              onChange={this.onChange}
              width={field.width}
              className="form-field"
            />
          </Form.Field>
        );
        break;
      case 'Component':
        element = (
          <Form.Field
            error={!!this.state.errors[field.name]}
            key={`field-${field.name}`}
            mobile={16}
            computer={field.width}
            className="form-field"
            widths={16}
          >
            <label htmlFor={field.name}>{field.caption}</label>
            <field.component
              key={`field-${field.name}`}
              onChange={value => this.onComponentChange(field.name, value)}
              data={this.state.data[field.name]}
              model={field}
            />
            {this.state.errors[field.name] && (
              <InlineError text={this.state.errors[field.name]} />
            )}
          </Form.Field>
        );
        break;
      default:
        break;
    }

    return element;
  };

  render() {
    const { model, loading } = this.props;

    return (
      <Form
        onSubmit={this.onSubmit}
        loading={loading}
        className="form-component"
      >
        {Object.keys(model.view).map(item => {
          if (model.view[item].editable) {
            return this.parseDataType(
              model.view[item],
              this.state.data[model.view[item].name]
            );
          }
          return null;
        })}
        <Form.Button
          width={16}
          className="form-button"
          disabled={!this.state.changed}
          color={this.state.changed ? 'green' : 'grey'}
        >
          Guardar
        </Form.Button>
      </Form>
    );
  }
}

FormComponent.defaultProps = {
  data: null,
  externalValidation: true
};

FormComponent.propTypes = {
  model: PropTypes.shape({}).isRequired,
  onStartEditing: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default FormComponent;
