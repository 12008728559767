import React from "react";
import PropTypes from 'prop-types';

import './style.css';

const FooterComponent = ({copyrigth, social}) => (
  <footer className="main-footer footer-component">
    <strong>{copyrigth}</strong>
    <div className="pull-right hidden-xs">
    {
      social.map(item => (
        <a  key={item.name} href={item.link}>
          <i className={ `fa fa-fw ${item.icon}` }/>
        </a>
      ))
    }
    </div>
  </footer>
)

FooterComponent.propTypes = {
  copyrigth: PropTypes.string.isRequired,
  social: PropTypes.arrayOf(PropTypes.shape({
    name: String,
    icon: String,
    link: String
  })).isRequired
};

export default FooterComponent;