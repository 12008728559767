import React from 'react';
import { withFirestore } from '../../hoc/firestore';
import { withCompany } from '../../hoc/company';
import { Loader } from 'semantic-ui-react';
import { InjectedCompanyProps } from '../../hoc/company/provider';

interface IDocumentParserProps {
  document: any;
  collectionName: string;
  state: any;
  actions: any;
  fields: string[];
}

class DocumentParser extends React.Component<
  IDocumentParserProps & InjectedCompanyProps
> {
  componentWillMount() {
    if (this.props.state && this.props.state[this.props.document.id]) {
      return;
    } else {
      this.props.actions.get(
        `companies/${this.props.company.state.selectedCompany.id}`,
        this.props.collectionName,
        this.props.document.id
      );
    }
  }

  render() {
    const { state, document, fields } = this.props;

    if (!this.props.state) return <Loader />;

    const doc = document ? state[document.id] : null;

    if (!doc) return <div>-</div>;

    const content = fields.reduce((acc, field) => {
      if (!acc) return doc[field] ? doc[field] : '';
      return `${acc}${doc[field] ? ' - ' + doc[field] : ''}`;
    }, null);

    return <div>{content}</div>;
  }
}

const mapState = (state: any, props: any) => {
  return state.data[props.collectionName];
};
export default withFirestore(mapState)(withCompany()(DocumentParser));
