import React from 'react';
import Validator from 'validator';
import { firestore } from 'firebase/app';
import CollectionList from '../components/CollectionList';
import DocumentParser from '../components/DocumentParser';
import FilesUploader from '../components/FilesUploader';

export const Phone = {
  validate: (value, errorMsg = null) => ({
    valid: Validator.isNumeric(value),
    error: errorMsg ? errorMsg : `Formato de teléfono no válido`
  })
};

export const CellPhone = Phone;

export const Email = {
  validate: (value, errorMsg = null) => ({
    valid: Validator.isEmail(value),
    error: errorMsg ? errorMsg : 'Email no válido'
  })
};

export const DNI = {
  validate: (value, errorMsg = null) => ({
    valid:
      Validator.isNumeric(value) &&
      Validator.isLength(value, { min: 8, max: 8 }),
    error: errorMsg ? errorMsg : 'DNI no válido (8 números)'
  })
};

export const CUIT = {
  validate: (value, errorMsg = null) => ({
    valid:
      Validator.isNumeric(value) &&
      Validator.isLength(value, { min: 11, max: 11 }),
    error: errorMsg ? errorMsg : 'CUIT no válido (11 números sin guiones)'
  })
};

export const CUIL = CUIT;

export const Timestamp = {
  defaultValue: () => firestore.Timestamp.now(),
  viewParser: firestoreDate => firestoreDate.toDate().toLocaleString()
};

export const Files = (path, fieldKey = false) => {
  return {
    name: 'files',
    caption: 'Archivos',
    type: 'Uploader',
    defaultValue: [],
    editable: false,
    show: true,
    files: true,
    viewParser: (fieldValue, document) => (
      <FilesUploader
        fieldKey={fieldKey}
        document={document}
        collection={path}
        files={document.files}
        uploadPath={
          fieldKey
            ? `${document.parentCollection}/${
                document.parentDocumentId
              }/${path}/${document.id}`
            : `${path}/${document.id}`
        }
      />
    )
  };
};

export const Text = {};

export const Select = {};

export const NotEmpty = {
  validate: (value, errorMsg = null) => {
    let valid = false;

    if (value !== 'undefined') {
      if (typeof value === 'object') {
        valid = Object.keys(value).length;
      } else {
        valid = !Validator.isEmpty(value);
      }
    }

    return {
      valid: valid,
      error: errorMsg ? errorMsg : `No puede estar vacío`
    };
  }
};

export const Name = {
  validate: (value, errorMsg = null) => ({
    valid: Validator.matches(value, /^([a-zA-Z])+(\s*[a-zA-Z])*$/gm),
    error: errorMsg ? errorMsg : 'Solo letras'
  })
};

export const Number = {
  validate: (value, errorMsg = null) => ({
    valid: Validator.isNumeric(value),
    error: errorMsg ? errorMsg : 'Solo numeros'
  })
};

export const Payroll = {
  searchInCollection: 'payrolls',
  searchFieldsInCollection: ['name'],
  validate: value => NotEmpty.validate(value),
  component: props => (
    <CollectionList
      {...props}
      collectionName="payrolls"
      fields={['lastName', 'name']}
    />
  ),
  viewParser: (document = null) => (
    <DocumentParser
      document={document}
      collectionName="payrolls"
      fields={['name', 'lastName']}
    />
  ),
  saveParser: value => ({ id: value.id })
};
