import components from './components';

const routes = [
    {
        path: '',
        title: 'List',
        description: '',
        icon: 'fa-building',
        component: components.List,
        default: true
    },
    {
        path: 'edit/:id',
        title: 'Edit',
        description: '',
        icon: 'fa-edit',
        component: components.Edit
    },
    {
        path: 'create',
        title: 'Nuevo',
        description: '',
        icon: 'fa-edit',
        component: components.Create
    }
];

export default routes;