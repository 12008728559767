import React from 'react';
import { PDFViewer } from '@react-pdf/renderer';
import PDFMarkup from './markup';

import { Button, Icon, Modal } from 'semantic-ui-react';
import { withFirestore, InjectedFirestoreProps } from '../../hoc/firestore';
import { withCompany } from '../../hoc/company';
import { InjectedCompanyProps } from '../../hoc/company/provider';

type EppPDFState = {
  loading: boolean;
  data: any;
  openModal: boolean;
};
class EppPDF extends React.Component<
  { item: any } & InjectedFirestoreProps & InjectedCompanyProps,
  EppPDFState
> {
  state = {
    loading: false,
    data: null,
    openModal: false
  };

  closeModal = () => {
    this.setState({ openModal: false });
  };

  showModal = () => {
    this.setState({ openModal: true });
    return;
  };

  getData = async () => {
    const {
      item: payroll,
      company,
      state: { data },
      actions
    } = this.props;

    let puesto;
    let elements = [];
    let epps = [];

    if (data['jobs'] && data['jobs'][payroll.puesto.id]) {
      puesto = data['jobs'][payroll.puesto.id];
    } else {
      puesto = await this.props.actions.get(
        `companies/${company.state.selectedCompany.id}`,
        'jobs',
        payroll.puesto.id
      );
    }

    const elementsPromises = puesto.elements.map(async element => {
      let el;
      if (data['elements'] && data['elements'][element.id]) {
        el = data['elements'][element.id];
      } else {
        el = await this.props.actions.get(
          `companies/${company.state.selectedCompany.id}`,
          'elements',
          element.id
        );
      }
      return () => {
        elements = [...elements, el];
      };
    });

    //Traer todos los epp para el usuario
    const rawEpps = await actions
      .fetch(`companies/${company.state.selectedCompany.id}`, 'epp', {
        field: 'payroll.id',
        condition: '==',
        value: payroll.id
      })
      .then(res => {
        return Object.values(res);
      });

    //Traer los items de cada epp
    const itemsPromises = rawEpps.map(async epp => {
      let item;

      if (data['items'] && data['items'][epp.item.id]) {
        item = data['items'][epp.item.id];
      } else {
        item = await this.props.actions.get(
          `companies/${company.state.selectedCompany.id}`,
          'items',
          epp.item.id
        );
      }

      return () => {
        epps = [...epps, { ...epp, item }];
      };
    });

    await Promise.all([...elementsPromises, ...itemsPromises]).then(res => {
      res.forEach(r => r());
    });

    return {
      epps,
      payroll,
      puesto,
      elements,
      company: company.state.selectedCompany
    };
  };

  handleOpenModal = async () => {
    this.setState({ loading: true }, () => {
      this.getData().then(data => {
        this.setState(
          {
            data,
            loading: false
          },
          () => {
            this.showModal();
          }
        );
      });
    });
  };

  render() {
    return (
      <>
        <Button
          size="mini"
          icon
          onClick={this.handleOpenModal}
          className="action-button"
        >
          {!this.state.loading ? (
            <>
              <Icon name="file pdf" />
              &nbsp;Reporte de Epp
            </>
          ) : (
            <Icon loading name="spinner" />
          )}
        </Button>
        <Modal
          size="large"
          open={this.state.openModal}
          onClose={this.closeModal}
        >
          <Modal.Content>
            <PDFViewer width="100%" height="500px">
              <PDFMarkup data={this.state.data} />
            </PDFViewer>
          </Modal.Content>
        </Modal>
      </>
    );
  }
}

export default withFirestore()(withCompany()(EppPDF));
