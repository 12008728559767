import React, { Component } from 'react';
import { AuthContext } from '../context';

import { auth } from 'firebase/app';
import 'firebase/auth';

class Auth extends Component {
  state = {
    isAuthenticating: true,
    authenticated: false,
    user: {
      role: 'visitor'
    },
    accessToken: ''
  };

  handleUserAuthChange = user => {
    if (user) {
      this.setState({
        isAuthenticating: false,
        authenticated: true,
        accessToken: 'token',
        user
      });
    } else {
      this.setState({
        isAuthenticating: false,
        authenticated: false,
        accessToken: '',
        user: {
          role: 'visitor'
        }
      });
    }
  };

  handleUserAuthChangeError = err => {
    console.log('Error auth user', err);
    this.setState({
      isAuthenticating: false,
      authenticated: false,
      accessToken: '',
      user: {
        role: 'visitor'
      }
    });
  };

  addAuthenticationListener = () => {
    this.authListenerUnsubscribe = auth().onAuthStateChanged(
      this.handleUserAuthChange,
      this.handleUserAuthChangeError
    );
  };

  componentDidMount() {
    this.addAuthenticationListener();
  }

  componentWillUnmount() {
    this.authListenerUnsubscribe();
  }

  initiateLogin = () => {
    console.log('Initiate Login process');
    window.location = '/login';
  };

  logout = () => {
    auth()
      .signOut()
      .then(function() {
        // Sign-out successful.
        console.log('Logout');
      })
      .catch(function(error) {
        // An error happened.
        console.log('Logout Error', error);
      });
  };

  handleAuthentication = () => {
    console.log('Handle Authentication Success');
  };

  render() {
    if (this.state.isAuthenticating) return null;

    const authProviderValue = {
      ...this.state,
      initiateLogin: this.initiateLogin,
      logout: this.logout,
      handleAuthentication: this.handleAuthentication
    };

    return (
      <AuthContext.Provider value={authProviderValue}>
        {this.props.children}
      </AuthContext.Provider>
    );
  }
}

export default Auth;
