import React from 'react';
import styles from '../../pdfStyles';
import { Page, Text, View, Document, Image } from '@react-pdf/renderer';

const EppsHeader = () => (
  <View style={styles.header}>
    <View style={styles.row}>
      <View style={[styles.column, { minHeight: '20px', width: '20%' }]}>
        <Text>Cant.</Text>
      </View>
      <View style={[styles.column, { minHeight: '20px', width: '20%' }]}>
        <Text>Producto</Text>
      </View>
      <View style={[styles.column, { minHeight: '20px', width: '20%' }]}>
        <Text>Modelo</Text>
      </View>
      <View style={[styles.column, { minHeight: '20px', width: '20%' }]}>
        <Text>Marca</Text>
      </View>
      <View style={[styles.column, { minHeight: '20px', width: '20%' }]}>
        <Text>Posee Cert.</Text>
      </View>
      <View style={[styles.column, { minHeight: '20px', width: '20%' }]}>
        <Text>Fecha de entrega</Text>
      </View>
      <View style={[styles.column, { minHeight: '20px', width: '20%' }]}>
        <Text>Firma del Trabajador</Text>
      </View>
    </View>
  </View>
);

const Content = ({ company, epps, payroll, puesto, elements }) => {
  return (
    <View style={styles.table}>
      <View style={[styles.row, { textAlign: 'right' }]}>
        <View style={styles.column}>
          <Text>Resolución 299/11, Anexo I</Text>
        </View>
      </View>
      <View style={styles.header}>
        <View style={[styles.row, styles.textCentered]}>
          <View style={[styles.column, styles.h3]}>
            <Text>
              ENTREGA DE ROPA DE TRABAJO Y ELEMENTOS DE PROTECCIÓN PERSONAL
            </Text>
          </View>
        </View>
      </View>
      <View style={styles.tableBody}>
        <View style={styles.row}>
          <View style={[styles.column, { width: '70%' }]}>
            <Text>Razón Social: {company.name}</Text>
          </View>
          <View style={[styles.column, { width: '30%' }]}>
            <Text>C.U.I.T.: {company.CUIT}</Text>
          </View>
        </View>
        <View style={styles.row}>
          <View style={[styles.column, { width: '40%' }]}>
            <Text>Dirección:</Text>
            <Text>{company.address}</Text>
          </View>
          <View style={[styles.column, { width: '30%' }]}>
            <Text>Localidad:</Text>
            <Text>{company.province.location.nombre}</Text>
          </View>
          <View style={[styles.column, { width: '10%' }]}>
            <Text>C.P.: {company.cp}</Text>
          </View>
          <View style={[styles.column, { width: '20%' }]}>
            <Text>Provincia:</Text>
            <Text>{company.province.province.nombre}</Text>
          </View>
        </View>
        <View style={styles.row}>
          <View style={[styles.column, { width: '70%' }]}>
            <Text>
              Nombre y Apellido del Trabajador: {payroll.name},{' '}
              {payroll.lastName}
            </Text>
          </View>
          <View style={[styles.column, { width: '30%' }]}>
            <Text>D.N.I.: {payroll.dni}</Text>
          </View>
        </View>
        <View style={styles.row}>
          <View style={[styles.column, { minHeight: '100px', width: '50%' }]}>
            <Text>
              Descripción breve del puesto/s de trabajo en el/los cuales se
              desmpeña el trabajador:
            </Text>
            <Text>
              {puesto.name}: {puesto.description}
            </Text>
          </View>
          <View style={[styles.column, { minHeight: '100px', width: '50%' }]}>
            <Text>
              Elementos de protección personal, necesarios para el trabajador,
              según el puesto de trabajo:
            </Text>
            {elements.map((element, index) => (
              <Text>
                {index + 1}){element.name}
              </Text>
            ))}
          </View>
        </View>
        <EppsHeader />
        {epps.map(epp => {
          return (
            <View style={styles.row}>
              <View
                style={[styles.column, { minHeight: '20px', width: '20%' }]}
              >
                <Text>{epp.quantity}</Text>
              </View>
              <View
                style={[styles.column, { minHeight: '20px', width: '20%' }]}
              >
                <Text>{epp.item.name}</Text>
              </View>
              <View
                style={[styles.column, { minHeight: '20px', width: '20%' }]}
              >
                <Text>{epp.item.description}</Text>
              </View>
              <View
                style={[styles.column, { minHeight: '20px', width: '20%' }]}
              >
                <Text>{epp.item.brand}</Text>
              </View>
              <View
                style={[styles.column, { minHeight: '20px', width: '20%' }]}
              >
                <Text>{epp.item.verification}</Text>
              </View>
              <View
                style={[styles.column, { minHeight: '20px', width: '20%' }]}
              >
                <Text>{epp.issueDate.toDate().toLocaleString()}</Text>
              </View>
              <View
                style={[styles.column, { minHeight: '20px', width: '20%' }]}
              >
                <Image src={epp.signature} />
              </View>
            </View>
          );
        })}
        <View style={styles.row}>
          <View style={styles.column}>
            <Text>Información adicional:</Text>
          </View>
        </View>
      </View>
    </View>
  );
};

const PDFMarkup = (props: any) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <Content {...props.data} />
        </View>
      </Page>
    </Document>
  );
};

export default PDFMarkup;
