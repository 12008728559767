import React from 'react';
import * as types from './types';
import CollectionList from '../components/CollectionList';
import DocumentParser from '../components/DocumentParser';
import SignComponent from '../components/SignComponent';

export const initialState = [];

export const view = {
  id: {
    name: 'id',
    caption: 'id',
    type: 'Text',
    defaultValue: '',
    editable: false,
    show: false
  },
  issueDate: {
    ...types.Timestamp,
    name: 'issueDate',
    caption: 'Fecha de entrega',
    type: 'Timestamp',
    editable: true,
    show: true
  },
  quantity: {
    name: 'quantity',
    caption: 'Cantidad',
    type: 'Number',
    defaultValue: '0',
    editable: true,
    show: true,
    validate: value => types.Number.validate(value)
  },
  payroll: {
    name: 'payroll',
    caption: 'Empleado',
    type: 'Component',
    defaultValue: '',
    editable: true,
    show: true,
    ...types.Payroll
  },
  item: {
    name: 'item',
    caption: 'Item',
    type: 'Component',
    defaultValue: '',
    editable: true,
    show: true,
    searchInCollection: 'items',
    searchFieldsInCollection: ['name'],
    validate: value => types.NotEmpty.validate(value),
    component: props => (
      <CollectionList
        {...props}
        collectionName="items"
        fields={['name', 'description']}
      />
    ),
    viewParser: (document = null) => (
      <DocumentParser
        document={document}
        collectionName="items"
        fields={['name', 'description']}
      />
    ),
    saveParser: value => ({ id: value.id })
  },
  signature: {
    name: 'signature',
    caption: 'Firma trabajador',
    type: 'Component',
    defaultValue: '',
    editable: true,
    show: true,
    width: 16,
    component: props => <SignComponent {...props} />,
    viewParser: base64Img =>
      base64Img ? (
        <img alt="signature" src={base64Img} width="50px" height="50px" />
      ) : (
        'Sin firma'
      )
  },
  aditionalInfo: {
    name: 'aditionalInfo',
    caption: 'Info adicional',
    type: 'Text',
    defaultValue: '',
    editable: true,
    show: true
  }
};

export const defaultSearchField = view.payroll;
export const filterFields = [view.payroll, view.item];
