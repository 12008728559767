import React from 'react';
import ListComponent from '../ListComponent';
import {
  Modal,
  Button,
  ButtonProps,
  ModalProps,
  Dimmer,
  Loader,
  Message
} from 'semantic-ui-react';
import EditComponent from '../EditComponent';
import { withFirestore } from '../../hoc/firestore';

type ModalAction = {
  caption: any;
  onClick: () => void;
  color: ButtonProps['color'];
};

type ModalConfig = {
  title: string;
  content: any;
  size?: ModalProps['size'];
  actions: ModalAction[];
};

type ModalListProps = {
  title: string;
  model: any;
  state: any;
  dataSelector: (state: any) => any;
  onCreate: (data: any) => Promise<any>;
  onDelete: (item: any) => Promise<any>;
  onEdit: (item: any) => Promise<any>;
};

type ModalListState = {
  loading: boolean;
  openModal: boolean;
  modal: ModalConfig;
  saved?: boolean;
  error?: string;
};

class ModalList extends React.Component<ModalListProps, ModalListState> {
  state = {
    loading: false,
    openModal: false,
    saved: false,
    error: null,
    modal: {
      title: '',
      content: null,
      actions: []
    } as ModalConfig
  };

  onCancel = () => {
    this.setState({ openModal: false, saved: false });
  };

  onEdit = async item => {
    const res = await this.props.onEdit({
      ...item
    });
    this.setState({ saved: true });
    return res;
  };

  onDelete = async item => {
    await this.props.onDelete(item);
    this.setState({ openModal: false });
  };

  onCreate = async item => {
    const res = await this.props.onCreate({
      ...item
    });
    this.setState({ saved: true });
    return res;
  };

  handleEdit = item => {
    this.setState({
      modal: {
        title: 'Editar',
        content: (
          <EditComponent
            data={item}
            model={this.props.model}
            onSubmit={this.onEdit}
            saved={this.state.saved}
            error={this.state.error}
            editing={true}
          />
        ),
        actions: [
          {
            caption: 'Salir',
            onClick: this.onCancel,
            color: 'green'
          }
        ]
      },
      openModal: true
    });
  };

  handleDelete = item => {
    this.setState({
      modal: {
        title: 'Eliminar',
        size: 'mini',
        content: <div>¿Seguro desea eliminar este registro?</div>,
        actions: [
          {
            caption: 'Si',
            onClick: () => this.onDelete(item),
            color: 'green'
          },
          {
            caption: 'No',
            onClick: this.onCancel,
            color: 'red'
          }
        ]
      },
      openModal: true
    });
  };

  handleCreate = () => {
    this.setState({
      modal: {
        title: 'Crear',
        content: (
          <EditComponent
            model={this.props.model}
            onSubmit={this.onCreate}
            saved={this.state.saved}
            error={this.state.error}
          />
        ),
        actions: [
          {
            caption: 'Salir',
            onClick: this.onCancel,
            color: 'green'
          }
        ]
      },
      openModal: true
    });
  };

  onSearch = async () => {
    console.log('Search');
    return new Promise((resolve, reject) => {
      resolve();
    });
  };

  render() {
    const {
      state: { data, loading, error }
    } = this.props;
    const { modal, saved } = this.state;

    return (
      <div>
        <ListComponent
          model={this.props.model}
          data={data || []}
          onEdit={this.handleEdit}
          onDelete={this.handleDelete}
          onCreate={this.handleCreate}
          onSearch={this.onSearch}
          loading={this.state.loading}
        />
        <Modal
          open={this.state.openModal}
          onClose={this.onCancel}
          size={modal.size || 'tiny'}
        >
          <Modal.Header>{modal.title}</Modal.Header>
          <Modal.Content scrolling>
            {loading && (
              <Dimmer active>
                <Loader />
              </Dimmer>
            )}
            {modal.content}
            {saved && !error && (
              <Message size="tiny" positive>
                Guardado!
              </Message>
            )}
          </Modal.Content>
          <Modal.Actions>
            {modal.actions.map(action => (
              <Button
                key={action.caption}
                color={action.color}
                onClick={action.onClick}
              >
                {action.caption}
              </Button>
            ))}
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const data = props.dataSelector(state.data);
  return { data, loading: state.loading, error: state.error };
};

export default withFirestore(mapStateToProps)(ModalList);
