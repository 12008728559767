import * as React from 'react';
import { Icon } from 'semantic-ui-react';
import { shape, string } from 'prop-types';
import * as types from './types';

import ProvinceSelector from '../components/ProvincesSelector';

export const propsDefinition = shape({
  name: string,
  lastName: string,
  phone: string
});

export const initialState = [];

export const view = {
  id: {
    name: 'id',
    caption: 'id',
    type: 'Text',
    defaultValue: '',
    editable: false,
    show: false
  },
  name: {
    name: 'name',
    caption: 'Razon Social',
    type: 'Text',
    defaultValue: '',
    editable: true,
    show: true,
    width: 4,
    validate: value => types.Name.validate(value, 'Nombre no válido')
  },
  CUIT: {
    name: 'CUIT',
    caption: 'CUIT',
    type: 'Text',
    defaultValue: '',
    editable: true,
    show: true
    //validate: (value) => ( types.CUIL.validate(value, 'CUIL no válido'))
  },
  address: {
    name: 'address',
    caption: 'Domicilio',
    type: 'Text',
    defaultValue: '',
    editable: true,
    show: false
  },
  province: {
    name: 'province',
    caption: 'Provincia',
    type: 'Component',
    defaultValue: '',
    editable: true,
    show: false,
    component: props => <ProvinceSelector {...props} />
  },
  cp: {
    name: 'cp',
    caption: 'C.P.',
    type: 'Text',
    defaultValue: '',
    editable: true,
    show: false
  },
  selected: {
    name: 'selected',
    caption: 'Seleccionada',
    type: 'Checkbox',
    defaultValue: false,
    editable: true,
    show: true,
    viewParser: value =>
      value ? (
        <Icon name="check circle" size="large" color="green" />
      ) : (
        <Icon name="close" size="large" color="grey" />
      )
  }
};

export const defaultSearchField = view.name;
export const filterFields = [view.name, view.CUIT];
