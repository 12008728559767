import * as React from 'react';
import { auth } from 'firebase/app';
import 'firebase/auth';

import firebaseui from 'firebaseui';

import 'firebaseui/dist/firebaseui.css';
import './style.css';

const firebaseUIConfig = {
  signInSuccessUrl: '',
  signInOptions: [auth.EmailAuthProvider.PROVIDER_ID],
  // tosUrl and privacyPolicyUrl accept either url string or a callback
  // function.
  // Terms of service url/callback.
  credentialHelper: firebaseui.auth.CredentialHelper.NONE,
  tosUrl: '<your-tos-url>',
  // Privacy policy url/callback.
  privacyPolicyUrl: function() {
    window.location.assign('<your-privacy-policy-url>');
  }
};

class LoginPage extends React.Component {
  componentDidMount() {
    this.ui =
      firebaseui.auth.AuthUI.getInstance() ||
      new firebaseui.auth.AuthUI(auth());
    this.ui.start('#firebase-ui-auth', firebaseUIConfig);
  }

  render = () => <div id="firebase-ui-auth" className="login-page" />;
}

export default LoginPage;
