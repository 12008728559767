import React from 'react';
import { Form, Message } from 'semantic-ui-react';

import * as model from './model';

class ContactComponent extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      data: model.defaultProps,
      errors: {},
      loading: false,
      startEditing: false,
      changed: false
    }
  }

  onChange = (e) => {

    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value },
      errors: {}
    });

    if (!this.state.startEditing) {
      this.enableSaveButton();
      this.setState({startEditing: true})
    }
  }

  disaleSaveButton = () => {
    this.setState({changed: false});
  }

  enableSaveButton = () => {
    this.setState({changed: true});
  }

  validate = (data) => {

    const errors = {};

    Object.keys(data).forEach(field => {
      if (model.view[field].validate) {

        const result = model.view[field].validate(data[field]);

        if (!result.valid)  errors[field] = result.error;
      }
    });

    return errors;
  }

  handleChange = (e, { name, value }) => {

    this.setState({
      data: { ...this.state.data, [name]: value },
      errors: {}
    });

    if (!this.state.startEditing) {
      this.enableSaveButton();
      this.setState({startEditing: true})
    }
  }

  handleSubmit = () => {

    this.disaleSaveButton();
    this.setState({startEditing: false})

    const errors = this.validate(this.state.data);
    this.setState({ errors });

    if (Object.keys(errors).length === 0) {
      console.log("no error => submit");
      setTimeout(() => {
        this.enableSaveButton();
      }, 2000);
    }

  }

  render = () => {

    const { name, lastName, email, phone, message, loading } = this.state

    return (
      <Form warning={!!Object.keys(this.state.errors).length} onSubmit={this.handleSubmit} loading={loading}>
        <Form.Group widths='equal'>
          <Form.Input
            label="Nombre"
            placeholder="Nombre"
            error={!!this.state.errors.name}
            name="name"
            value={name}
            onChange={this.handleChange} />
          <Form.Input
            label="Apellido"
            placeholder="Apellido"
            error={!!this.state.errors.lastName}
            name="lastName"
            value={lastName}
            onChange={this.handleChange} />
        </Form.Group>
        <Form.Group widths='equal'>
          <Form.Input
            label="Teléfono"
            placeholder="Teléfono"
            error={!!this.state.errors.phone}
            name="phone"
            value={phone}
            onChange={this.handleChange} />
          <Form.Input
            label="Email"
            placeholder="Email"
            error={!!this.state.errors.email}
            name="email"
            value={email}
            onChange={this.handleChange} />
        </Form.Group>
        <Form.TextArea
          error={!!this.state.errors.message}
          label='Mensaje'
          placeholder='Dejanos tu consulta, un representante se pondrá en contacto contigo a la brevedad'
          name="message"
          value={message} onChange={this.handleChange}
        />
        <Message
          size='tiny'
          warning
          header='Ops! Por favor revisá lo siguiente e intentá de nuevo:'
          list={Object.keys(this.state.errors).map(key => this.state.errors[key])}
        />
        <Form.Button disabled={!this.state.changed}> Enviar</Form.Button>
      </Form>
    )
  }
    
}

ContactComponent.propTypes = {}

export default ContactComponent;