import React from 'react';
import RiskModel from '../../models/RiskModel';
import ModalListComponent from '../ModalList/ModalListComponent';

function Risks({ item }) {
  return (
    <ModalListComponent
      model={RiskModel}
      document={item}
      collectionName="payrolls"
      field="risks"
      buttonText="Riesgos"
      buttonIcon="warning sign"
      buttonColor="orange"
    />
  );
}

export default Risks;
