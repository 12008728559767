import React from 'react';
import { Route, Redirect, RouteComponentProps } from 'react-router-dom';
import { AuthContext } from './context';

interface PrivateRouteProps {
  component: React.ComponentType<any>;
  path: string;
}

const PrivateRoute: React.SFC<PrivateRouteProps> = ({
  component: Component,
  ...rest
}): any => (
  <AuthContext.Consumer>
    {({ authenticated, user }) => {
      if (authenticated) {
        return (
          <Route {...rest} render={props => <Component uid={user.uid} />} />
        );
      }
      return <Redirect to="/" />;
    }}
  </AuthContext.Consumer>
);

export default PrivateRoute;
