import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { withRouter } from 'react-router';

class BaseComponent extends React.Component {
  render() {
    const { routes } = this.props;
    return (
      <Switch>
        {routes.map(route => (
          <Route
            exact
            key={route.path}
            path={`${this.props.match.path}/${route.path}`}
            render={() => <route.component {...this.props} />}
          />
        ))}
        <Redirect to={`/`} />
      </Switch>
    );
  }
}

export default withRouter(BaseComponent);
