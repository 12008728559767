import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { AuthContext } from './context';

import PrivateRoute from './PrivateRoute';
import HomePage from './pages/HomePage';
import DashboardPage from './pages/DashboardPage';
import CallbackPage from './pages/CallbackPage';
import LoginPage from './pages/LoginPage';

const App = () => (
  <Switch>
    <Route exact path="/" component={HomePage} />
    <PrivateRoute path="/dashboard" component={DashboardPage} />
    <Route
      path="/login"
      render={props => (
        <AuthContext.Consumer>
          {({ authenticated }) => {
            if (authenticated) {
              return <Redirect to="/dashboard" />;
            }

            return <LoginPage {...props} />;
          }}
        </AuthContext.Consumer>
      )}
    />
    <Route path="/callback" component={CallbackPage} />
    <Redirect to="/" />
  </Switch>
);

export default App;
