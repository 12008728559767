import React from 'react';
import { FirestoreContext } from './provider';

const withFirestore = (mapState?: (state: any, props: any) => any, mapActions?: (actions: any) => any) => {
  return (Component: React.ComponentType) => {
    return (props: any) => {
      return (
        <FirestoreContext.Consumer>
          {
            ({ state, actions }) => {

              return(
                <Component
                  {...props}
                  state={mapState ? mapState(state, props) : state}
                  actions={mapActions ? mapActions(actions) : actions}
                />
              )
            }
          }
        </FirestoreContext.Consumer>
      )
    }
  }
}

export default withFirestore;